import { Directive, ViewContainerRef, AfterViewInit, OnInit, TemplateRef, ElementRef } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { CdkTable } from '@angular/cdk/table';

@Directive({
  selector: '[tableExporter]'
})
export class TableExporterDirective implements OnInit, AfterViewInit {

  private _cdkTable: any;

  get cdkTable(): any {
    return this._cdkTable;
  }
  /**
   * Overriding ngAfterViewInit of TableExporter
   */
  ngAfterViewInit(): void {
 //
 //
  }
  ngOnInit(): void {
 //
 //
  }
  constructor(
    protected table: MatTable<any>,
    // protected tpl: TemplateRef<any>,
    protected host: ElementRef,

    protected viewContainerRef: ViewContainerRef) {
   //
      this.initCdkTable();

 //
  }
  private initCdkTable() {
    // tslint:disable-next-line:no-string-literal
 //
    if(this.viewContainerRef['_data']) {
      const table = this.viewContainerRef['_data'].componentView.component;
      if (table) {
        this._cdkTable = table;
      // } else if (this.table) {
      //  this._cdkTable = this.table;
      } else {
        throw new Error('Unsupported Angular version');
      }
    }
    else if (this.table) {
      this._cdkTable = this.table;
    }

  }
  /**
   * MatTable implementation of getPageCount
   * @override
   */
  public getPageCount(): number {
    return this.getPaginator().getNumberOfPages();
  }

  /**
   * MatTable implementation of getCurrentPageIndex
   * @override
   */
  public getCurrentPageIndex(): number {
    return this.getPaginator().pageIndex;
  }

  /**
   * MatTable implementation of goToPage
   * @override
   */
  public goToPage(index: number): void {
    this.getPaginator().pageIndex = index;
    this.getPaginator()._changePageSize(this.getPaginator().pageSize);
  }

  /**
   * MatTable implementation of getPageChangeObservable
   * @override
   */
  public getPageChangeObservable(): Observable<any> {
    return this.getPaginator().page;
  }


  private getPaginator(): MatPaginator {
    return (this.cdkTable.dataSource as MatTableDataSource<any>).paginator;
  }

  private enablePaginator(value: boolean) {
    if (this.getPaginator()) {
      this.getPaginator().disabled = !value;
      this.getPaginator()._changePageSize(this.getPaginator().pageSize);
    }
  }

}
