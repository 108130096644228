<div class="formWhiteBox">
    <div class="row justify-content-md-center">
        <div class="col-md-6">         
			<div class="sign-logo text-center">
				<a href="#">
				  <img src="assets/img/glasswing_logo.svg">
				</a>
			</div>
			<div class="loginMainDiv">
			<h1 class="pBtm0">Forgot Your Password</h1> 
			{{sucessMessage}}
			<form class="example-form forg-rest-pass" [formGroup]="forgotFormGroup" >
				<mat-form-field class="mBtm15 w-100">
				   <input type="text" matInput placeholder="Email" formControlName="email">
				   <mat-error *ngIf="!forgotFormGroup.get('email').valid && this.forgotFormGroup.get('email').touched">
						Please enter your registered email.
					</mat-error>
				</mat-form-field>					
				<div class="row mBtm10">
					<div class="col-sm-6 text-left">
					   <button mat-raised-button color="primary" (click)="submit(forgotFormGroup.value)" >Send My Password</button>
					</div>
                	<div class="col-sm-6 text-right">
					   <button mat-raised-button [routerLink]="['/user/login']" >Cancel</button>
					</div>						
				</div>
            </form>
        </div>
        </div>
    </div>
</div>