import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs";
import { MatDrawer } from "@angular/material/sidenav";
@Injectable()
export class CommonService {
  private static isHeader = new BehaviorSubject<boolean>(false);

  castLogin$: Observable<boolean> = CommonService.isHeader.asObservable();

  public changeFont = new BehaviorSubject<string>("");
  public showToogle = new BehaviorSubject<boolean>(false);

  fontChanger$: Observable<string>;
  //  = CommonService.changeFont.asObservable();

  private drawer: MatDrawer;
  private fontSize: MatDrawer;
  constructor() {
    this.fontChanger$ = this.changeFont.asObservable();

  }

  public changeHeader(status: boolean) {
    CommonService.isHeader.next(status);
  }

  setDrawer(drawer: MatDrawer) {
    this.drawer = drawer;
  }

  toggle(): void {
    this.drawer.toggle();
  }

  close(): void {
    this.drawer.close();
  }
  public setUrl(data: string) {
    if (data.endsWith("/priority")) {
      this.showToogle.next(true);
    } else {
      this.showToogle.next(false);
    }
  }
}
