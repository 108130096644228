import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-ath-bth-priority-dialog",
  templateUrl: "./ath-bth-priority-dialog.component.html",
  styleUrls: ["./ath-bth-priority-dialog.component.css"],
})
export class AthBthPriorityDialogComponent implements OnInit {
  remarks = "";
  heading = "";
  subtext = "";
  selectedOption = undefined;
  priority;
  multipleSelect;
  optionValue = [
    { value: "steak-0", viewValue: "Prioritize all" },
    { value: "pizza-1", viewValue: "Accept all" },
    { value: "tacos-2", viewValue: "Reject all" },
  ];
  constructor(
    public dialogRef: MatDialogRef<AthBthPriorityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.priority = this.data.priority;
    this.multipleSelect = this.data.multipleSelect;
    this.remarks = "";
    this.heading = this.data.heading;
    this.subtext = this.data.subtext;

  }

  ngOnInit(): void {}

  remarksCondition() {
    if (this.selectedOption === "steak-0" && this.remarks.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
