import { Component, OnInit, Output, Input, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
@Component({
  selector: 'app-mat-error',
  templateUrl: './mat-error.component.html',
  styleUrls: ['../../shared/layout/dropdowns/dropdowns.component.css']
})
export class MatErrorComponent implements OnInit, OnChanges {
  @Input() control: FormControl;
  @Input() displayName: string;
  @Input() type: string;
  @Input() placeholder: string;
  _isValid: boolean;
  _isTouched: boolean;
  constructor(private _formBuilder: FormBuilder) {
  }
  ngOnInit() {
 //
 //
 //

  }
  isControlValid(){
    return this.control.valid;
  }
  ngOnChanges(){
 //
 //

  }
  @Input()
  set valid(isValid:boolean){
 //
    this._isValid = isValid;
    if(!this._isValid){
    }

  }
  @Input()
  set touched(_isTouched:boolean){
 //
    this._isTouched = _isTouched;
    if(this._isTouched && !this._isValid){
    }
    if(this._isTouched && !this._isTouched){
      //
    }
  }
  @Input()
  set markAsTouched(opts: any){
 //

    this._isTouched = true;
    this.touched = true;
  }
  set setErrors(opts: any){
 //
 //
    this._isTouched = true;
    this.touched = true;

  }
  shouldDisplayError(){
 //
 //
    return this.control.valid && this.control.touched;
  }
  hasRequiredError(){
 //
 //
 //
    return this.control.errors && this.control.errors.required
  }
}
