<mat-form-field class="w-100 mat-form-field mat-form-field-required {{_invalidClass}}">
  <!-- <mat-form-field class="w-100"> -->
  <mat-select disabled={{diabled}} required class="mat-select mat-select-required {{_invalidClassSelect}}"

  multiple={{multiple}} placeholder={{placeholder}} [(value)]="selected" (selectionChange)="selectId()"
  (click)="setTouchedSelf()" (keyup)="setTouchedSelf()">
    <mat-option *ngFor="let element of list"  [value]="element.dd_key">{{element.dd_value_short || element.dd_value}}</mat-option>
  </mat-select>
  <mat-hint><ng-container *ngIf="list[0]">Like {{list[0].dd_value_short | titlecase}}</ng-container> <ng-container *ngIf="list[1]">,{{list[1].dd_value_short | titlecase}}..</ng-container></mat-hint>
</mat-form-field>
