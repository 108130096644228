import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpService } from '../../../shared/core/services/http-service.service'

import { ResponseModel } from '../../../shared/core/model/response';
@Injectable()
export class NotificationsService {
  notificationsList = [];

  notificationsListChange: Subject<Array<any>> = new Subject<Array<any>>();

  constructor(private serviceHttp:HttpService) {
    this.notificationsListChange.subscribe((value) => {
      this.notificationsList = value
  });
  }
  updatenotificationsList(aData) {
    this.notificationsListChange.next(aData);
  }
  getList(pageNo = 1
    ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('notifications', 'GET', 0, null, 0, 1);
  }
  search(str:string ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('notifications/search', 'POST', 0, {search:str });
  }
  getNotificationsById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('notifications/'+ id, 'GET');
  }
  addNotifications(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('notifications', 'POST', 0, data);
  }
  updateNotifications(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('notifications/' + data.id, 'PUT', 0, data);
  }
}
