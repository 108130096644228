export class DropdownRelations{
    public static data: any = {
        "business_type_cd": {
            "DRY": {
                "name": "DRY",
                "booking_type_cd": ['SPOT', 'CONTRACT', 'LEAD'],
                "movement_type": [],
                "movement_business_type": [],
                "truck_type_cd": ['37','19', '36'],
                "service_type_cd": ['FTL', 'FTL Express', 'FTL SUPER EXPRESS'] 
            },
            "EXIM": {
                "name": "EXIM",
                "booking_type_cd": ['SPOT', 'CONTRACT', 'LEAD'],
                "service_type_cd": ['FTL', 'FTL Express'], 
                "movement_type": ["all"],
                "truck_type_cd": ['36'],
                "movement_business_type": ["all"]
            },
            "REEFER": {
                "name": "REEFER",
                "booking_type_cd": ['SPOT', 'CONTRACT', 'LEAD'],
                "service_type_cd": ['FTL'], 
                "truck_type_cd": ['36'],
                "movement_type": [],
                "movement_business_type": []
            }
        },
        "service_type_cd": {
            "FTL": {
                "name": "FTL",
                "truck_type_cd": ['36', '19']
            },
            "FTL Express": {
                "name": "FTL EXPRESS",
                "truck_type_cd": ['36']
            },
            "FTL SUPER EXPRESS": {
                "name": "FTL SUPER EXPRESS",
                "truck_type_cd": ['36']
            }
        }
    };
}