<header class="header" id="header">
  <div class="logo pull-left">
    <a [routerLink]="['user/login']">
      <img src="assets/img/glasswing_logo.svg">
    </a>
  </div>

  <div class="headerSearchBar headerSearchBar1" *ngIf="user?.roles_id == 3 && !advanceSearchFlag">
    <input type="text" class="form-searchbar" [(ngModel)]="inputSearch" (keydown)="getSerachData($event)"
      placeholder="Search">
    <span class="search-btn"><i class="ti-search"></i> </span>

    <button mat-raised-button (click)="onClickAdvanceSearch()" class="confirm_btn blueButton saveButton">Advance
      Seach</button>
  </div>

  <div class="headerSearchBar advsearch" *ngIf="user?.roles_id == 3 && advanceSearchFlag">
    <button mat-raised-button (click)="onClickAdvanceSearch()" class="advSeachBtn"> <span
        style="font-weight: bold;font-size: 15px;margin-right: 6px;">
    </span> Advance Seach</button>

    <mat-form-field class="mat-form-field" style="margin: 0px 20px;">
      <mat-select placeholder="Origin" [(value)]="selected">
        <!--  (selectionChange)="selectId()" (click)="setTouchedSelf()" (keyup)="setTouchedSelf()" -->
        <mat-option *ngFor="let element of list" [value]="element.id">{{element.name || element.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="mat-form-field" style="margin-right: 20px;">
      <mat-select placeholder="Destination" [(value)]="selected">
        <!--  (selectionChange)="selectId()" (click)="setTouchedSelf()" (keyup)="setTouchedSelf()" -->
        <mat-option *ngFor="let element of list" [value]="element.id">{{element.name || element.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="mat-form-field">
      <mat-select placeholder="Date Range" [(value)]="selected">
        <!--  (selectionChange)="selectId()" (click)="setTouchedSelf()" (keyup)="setTouchedSelf()" -->
        <mat-option *ngFor="let element of list" [value]="element.id">{{element.name || element.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- <div class="advancesearchbox"  *ngIf="user?.roles_id == 3" (click)="getSerachData()"><span class="addSearch">Search</span></div> -->

  <div class="rightUserSec ">
    <div class="notificationSection notificationSection-plus"
      *ngIf="userCanCreateOrder && (user?.roles_id == 1 || user?.roles_id == 10)">
      <!-- <div class="notificationSection"> -->
      <button mat-button class="blueButton newOrdBtn " (click)="createBooking()">
        <span class="btnBorder nOrdBtn">
          Create Order</span>
      </button>
    </div>
    <div class="headerCell">
      <div class="notificationSection">
        <button class="userIcon" mat-icon-button [matMenuTriggerFor]="menu" (click)="notificationClick()"
          (click)="notificationClick()" [matBadge]="notificationLength()" matBadgePosition="after"
          matBadgeColor="accent">
          <img src="assets/img/new icons/notification.svg" alt="" class="header_img">
        </button>
        <!-- <button mat-icon-button [matMenuTriggerFor]="menu" (click)="notificationClick()" class="userIcon">
        <mat-icon class="icon_bell" [matBadge]="notificationLength()" matBadgePosition="above after"
          [matBadgeColor]="badgeColor" style="margin-top: 8px;">
          <img src="assets/img/new icons/notification.svg" alt="" class="header_img not__icon" style="margin-top: -9px;">
        </mat-icon>
      </button> -->
        <mat-menu #menu="matMenu" class="notificationMenuBox">
          <div class="listview p-2">
            <div class="lv-header">Notifications</div>
            <!-- *ngFor="let notification of notificationsList;let i=index " -->
            <div class="lv-body" *ngFor="let notification of notificationsList;let i=index ">
              <a class="listData" *ngIf="i < 3">
                <div *ngIf="notification">
                  <div class="textSec">
                    <small class="lv-small"> {{notification.data.message}} </small>
                  </div>
                </div>
              </a>
            </div>
            <div class="clearfix"></div>
            <a class="lv-footer" [routerLink]="['notifications/list']">View All Notifications</a>
          </div>
        </mat-menu>
      </div>
    </div>
    <!-- <button class="flag" mat-icon-button [matMenuTriggerFor]="language">
         <div class="flag-pic">
           <img src="assets/img/eng.svg" alt="user">
         </div>
      </button> -->
    <!-- <mat-menu #language="matMenu">
      <div class="dropdown-menu dropdown-menu-right">
        <a class="dropdown-item" href="#">
          <img src="assets/img/india.svg" alt="user">
        Hindi</a>
        <a class="dropdown-item" href="#"><i class="flag-icon flag-icon-us"></i> English</a>
      </div>
      </mat-menu>  -->
    <div class="notificationSection">
      <button class="userIcon" mat-icon-button mat-icon-button [matMenuTriggerFor]="settingsMenu">
        <img src="assets/img/new icons/settings.svg" alt="" class="header_img">
      </button>
    </div>
    <div class="notificationSection">
      <button class="userIcon" mat-icon-button [matMenuTriggerFor]="userProfileMenu">
        <img src="assets/img/new icons/profile.svg" alt="" class="header_img">
      </button>
    </div>
    <!-- <div class="notificationSection">
      <button class="userIcon" mat-icon-button (click)="fontSizeIncrease()">
        <mat-icon>text_increase</mat-icon>
      </button>
    </div> -->
    <mat-menu #settingsMenu="matMenu" >
      <button class="userIcon" mat-icon-button (click)="fontSizeIncrease()">
        <mat-icon>text_increase</mat-icon>
      </button>

      <button class="userIcon" mat-icon-button (click)="fontSizeDecrease()">
        <mat-icon>text_decrease</mat-icon>
      </button>
      <div class="divider"></div>
      <button class="userIcon" mat-icon-button (click)="fontWeightIncrease()">
        <mat-icon>line_weight</mat-icon>
      </button>
    </mat-menu>
    <mat-menu #userProfileMenu="matMenu" class="userProfileMenuBox">
      <div class="headerUserSec">
        <ul class="dropdown-user">
          <li role="separator" class="divider">
          </li>
          <li><a (click)="logout()"><i class="fa fa-power-off"></i>
              <ul>
                <li><span>{{user?.name}}</span></li>
                <li><span>{{getCompanyName()}}</span></li>
              </ul>
            </a>
          </li>
          <!-- <li><a (click)="logout()"><i class="fa fa-power-off"></i>
            <div></div>
              Logout {{user?.name}} {{getCompanyName()}}
            </a>
          </li> -->
        </ul>
      </div>
    </mat-menu>
    <!-- </div>
  <div class="rightUserSec headerRightActions"> -->

  </div>
</header>
