<div class="user-panel text-center" (click)="toggleDrawer()">
  <div class="userprofile-pic">
    <div class="borderpic"><img [src]="avatar_url" class="user_img" /></div>
  </div>
  <p *ngIf="checkRole([3])" class="user_name">{{user.name}}</p>
  <p *ngIf="checkRole([3])" class="user_company">{{company_name}}</p>
</div>

<mat-accordion *ngIf="isShow && checkRole([3])">
  <mat-expansion-panel class="singleMenuLink dMenu" routerLink="/dashboard" routerLinkActive="active"
    (click)="toggleDrawer()">
    <mat-expansion-panel-header *ngIf="!panelOpenState" [collapsedHeight]="customCollapsedHeight"
      [expandedHeight]="customExpandedHeight">
      <mat-panel-title>
        <span class="dashboardMenu">&nbsp;</span><span>Dashboard</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <mat-expansion-panel class="oMenu" hideToggle="true" #example>
    <mat-expansion-panel-header *ngIf="!panelOpenState" [collapsedHeight]="customCollapsedHeight"
      [expandedHeight]="customExpandedHeight">
      <mat-panel-title>
        <span class="ordersMenu">&nbsp;</span><span>Orders</span>
        <mat-icon class="arrow_icon">{{!example.expanded ? 'keyboard_arrow_right' : 'keyboard_arrow_down' }}</mat-icon>
        <!-- <md-icon *ngIf="!example.expanded">keyboard_arrow_right</md-icon>
        <md-icon *ngIf="example.expanded">keyboard_arrow_down</md-icon> -->
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul class="submenu">
      <li (click)="toggleDrawer()"><a [routerLink]="['/customer-bookings/add']" routerLinkActive="active">
          <mat-icon>lens</mat-icon> New Order
        </a></li>
      <li (click)="toggleDrawer()"><a [routerLink]="['/customer-bookings/list']" routerLinkActive="active">
          <mat-icon>lens</mat-icon> Active Order
        </a></li>
      <li (click)="toggleDrawer()"><a [routerLink]="['/customer-bookings/completed-list']" routerLinkActive="active">
          <mat-icon>lens</mat-icon> Completed Order
        </a></li>
      <li (click)="toggleDrawer()"><a [routerLink]="['/customer-bookings/unprocessed-list']" routerLinkActive="active">
          <mat-icon>lens</mat-icon> Unprocessed Order
        </a></li>
      <!-- <li><a [routerLink]="['/customer-bookings/spotlisting']" routerLinkActive="active" >
          <mat-icon>lens</mat-icon> Spot Order
        </a></li> -->
      <li (click)="toggleDrawer()"><a routerLink="/customer-bookings/favourites-order" routerLinkActive="active">
          <mat-icon>star</mat-icon> Favourites Order
        </a></li>
    </ul>
  </mat-expansion-panel>
  <mat-expansion-panel style="display: none" class="singleMenuLink tMenu" routerLink="#" routerLinkActive="active"
    (click)="toggleDrawer()">
    <mat-expansion-panel-header *ngIf="!panelOpenState" [collapsedHeight]="customCollapsedHeight"
      [expandedHeight]="customExpandedHeight">
      <mat-panel-title>
        <span class="tripsMenu">&nbsp;</span><span>Trips</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <mat-expansion-panel class="singleMenuLink pMenu" [routerLink]="['/customer-bookings/payment']"
    routerLinkActive="active" (click)="toggleDrawer()">
    <mat-expansion-panel-header *ngIf="!panelOpenState" [collapsedHeight]="customCollapsedHeight"
      [expandedHeight]="customExpandedHeight">
      <mat-panel-title>
        <span class="paymentsMenu">&nbsp;</span><span class="m-t-7">Payments</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <mat-expansion-panel class="singleMenuLink rMenu" [routerLink]="['/customer-bookings/reports']"
    routerLinkActive="active" (click)="toggleDrawer()">
    <mat-expansion-panel-header *ngIf="!panelOpenState" [collapsedHeight]="customCollapsedHeight"
      [expandedHeight]="customExpandedHeight">
      <mat-panel-title>
        <span class="reportsMenu">&nbsp;</span><span class="m-t-7">Reports</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</mat-accordion>
<ul class="navGroups" style="height: -webkit-fill-available;" *ngIf="isShow && isInternalErp &&  checkRole([1, 10])">
  <li *ngIf="checkRole([10, 1])">
    <a (click)="$event.preventDefault(); IsExpandedTask = !IsExpandedTask;">
      <img src="assets/img/svg images/lr.svg" alt="" height="40">
      <span style="    margin-left: 6%;">My Tasks</span>
      <span class="material-icons lefbar_arrow" *ngIf="!IsExpandedTask">
        keyboard_arrow_right
      </span>
      <span class="material-icons lefbar_arrow" *ngIf="IsExpandedTask">
        keyboard_arrow_down
      </span>
    </a>
    <div *ngIf="IsExpandedTask ">
      <ul class="navGroupsNested">
        <li *ngIf="checkRole([10, 1]) && checkWA('BOOKING EXECUTIVE') " (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'BOOKING EXECUTIVE', user.id]" routerLinkActive="active">
            <span class="nestPadding">Booking Exec</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && checkWA('ROH')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'ROH', user.id]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">Sourcing Lead</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && checkWA('TRAFFIC EXECUTIVE')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'TRAFFIC EXECUTIVE', user.id]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">Sourcing Manager</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && checkWA('LR EXECUTIVE')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'LR EXECUTIVE', user.id]" routerLinkActive="active">
            <span class="nestPadding">LR Exec</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && checkWA('CT EXECUTIVE')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'CT EXECUTIVE', user.id]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">Ctrl Tower</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && checkWA('COORDINATOR')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'COORDINATOR', user.id]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">Coordinator</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && checkWA('FINANCE EXECUTIVE')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'FINANCE EXECUTIVE', user.id]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
\            <span class="nestPadding">Finance</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && checkWA('KAM')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'KAM', user.id]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">KAM</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])  && checkWA('MANAGEMENT')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/tasks']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">Manage</span>
          </a>
        </li>
      </ul>
    </div>
  </li>
  <li *ngIf="checkRole([10, 1])">
    <a (click)="$event.preventDefault(); IsExpandedTask = !IsExpandedTask;">
      <img src="assets/img/svg images/lr.svg" alt="" height="40">
      <span style="    margin-left: 6%;">Group Tasks</span>
      <span class="material-icons lefbar_arrow" *ngIf="!IsExpandedTask">
        keyboard_arrow_right
      </span>
      <span class="material-icons lefbar_arrow" *ngIf="IsExpandedTask">
        keyboard_arrow_down
      </span>
    </a>
    <div *ngIf="IsExpandedTask ">
      <ul class="navGroupsNested">
        <li *ngIf="checkRole([10, 1]) && checkWA('BOOKING EXECUTIVE') " (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'BOOKING EXECUTIVE', 0]" routerLinkActive="active">
            <span class="nestPadding">Booking Exec</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && checkWA('ROH')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'ROH', 0]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">Sourcing Lead</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && checkWA('TRAFFIC EXECUTIVE')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'TRAFFIC EXECUTIVE', 0]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">Sourcing Manager</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && checkWA('LR EXECUTIVE')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'LR EXECUTIVE', 0]" routerLinkActive="active">
            <span class="nestPadding">LR Exec</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && checkWA('CT EXECUTIVE')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'CT EXECUTIVE', 0]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">Ctrl Tower</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && checkWA('COORDINATOR')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'COORDINATOR', 0]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">Coordinator</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && checkWA('FINANCE EXECUTIVE')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'FINANCE EXECUTIVE', 0]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
\            <span class="nestPadding">Finance</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && checkWA('KAM')" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'KAM', 0]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">KAM</span>
          </a>
        </li>
      </ul>
    </div>
  </li>

</ul>
<ul class="navGroups" *ngIf="isShow && !isInternalErp &&  checkRole([1, 10])">
  <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
    <a [routerLink]="['/1/triplite/internal/mytasks', user_id]" routerLinkActive="active">
      <img src="assets/img/svg images/vendors.svg" alt="" height="35">
      <span style="margin-left: 8%;">Tasks Beta</span>
    </a>
  </li>
  <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
    <a [routerLink]="['/bookings']" routerLinkActive="active">
      <img src="assets/img/svg images/booking.svg" alt="" height="40">
      <span style="    margin-left: 6%;">Bookings</span>
    </a>
  </li>
  <li *ngIf="checkRole([10, 1, 2, 4, 5])" (click)="toggleDrawer()">
    <a [routerLink]="['/1/triplite/list']" routerLinkActive="active">
      <img src="assets/img/svg images/truck.svg" alt="" style="margin-left: 3px;" height="35">
      <span style="    margin-left: 7%;">Trips</span>
    </a>

  </li>
  <li *ngIf="checkRole([10, 1])  && !userIsLE()" (click)="toggleDrawer()">
    <a [routerLink]="['/1/driverTrips/partners']" routerLinkActive="active">
      <img src="assets/img/svg images/booking.svg" alt="" style="margin-left: 3px;" height="35">
      <span style="    margin-left: 7%;">Partner Quotes</span>
    </a>
  </li>
  <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
    <a [routerLink]="['/1/triplite/cnotelist']" routerLinkActive="active">
       <img src="assets/img/svg images/lr.svg" alt="" height="40">
      <span style="margin-left: 6%;">Consignment Notes</span>
    </a>
  </li>
  <li *ngIf="checkRole([10, 1, 2, 4, 5]) && !userIsLE()" (click)="toggleDrawer()">
    <a [routerLink]="['/truckHireChallans']" routerLinkActive="active">
      <img src="assets/img/svg images/truck.svg" alt="" style="margin-left: 3px;" height="35">
      <span style="    margin-left: 7%;">Truck Hire Challans</span>
    </a>
  </li>
  <li *ngIf="checkRole([10, 1])">
    <a (click)="$event.preventDefault(); IsExpandedTask = !IsExpandedTask;">
      <img src="assets/img/svg images/lr.svg" alt="" height="40">
      <span style="    margin-left: 6%;">Tasks</span>
      <span class="material-icons lefbar_arrow" *ngIf="!IsExpandedTask">
        keyboard_arrow_right
      </span>
      <span class="material-icons lefbar_arrow" *ngIf="IsExpandedTask">
        keyboard_arrow_down
      </span>
    </a>
    <div *ngIf="IsExpandedTask ">
      <ul class="navGroupsNested">
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'BOOKING EXECUTIVE', 0]" routerLinkActive="active">
            <span class="nestPadding">Booking Exec</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'ROH', 0]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">Sourcing Lead</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'TRAFFIC EXECUTIVE', 0]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">Sourcing Manager</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'LR EXECUTIVE', 0]" routerLinkActive="active">
            <span class="nestPadding">LR Exec</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'CT EXECUTIVE', 0]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">Ctrl Tower</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'COORDINATOR', 0]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">Coordinator</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'FINANCE EXECUTIVE', 0]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
\            <span class="nestPadding">Finance</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'KAM', 0]" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">KAM</span>
          </a>
        </li>
        <!-- <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/workarea', 'MANAGEMENT']" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span>Management</span>
          </a>
        </li> -->
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal']">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">All</span>
          </a>
        </li>
        <!-- <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/internal/group/myorg']" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span>My Org</span>
          </a>
        </li> -->
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/1/triplite/tasks']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <span class="nestPadding">Manage</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/stateLocationMappings/list']">
            <i *ngIf="displayIcon" class="fa fa-user" aria-hidden="true"></i>
            <span class="nestPadding">Branch Mapping</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/goals/list']" routerLinkActive="active">
            <img src="assets/img/svg images/settings-2.svg" alt="" height="30" style="padding-left: 5px;">
            <span style="margin-left: 8%;">Manage Goals</span>
          </a>
        </li>
      </ul>
    </div>
  </li>
  <li *ngIf="checkRole([10, 1])">
    <a (click)="$event.preventDefault(); IsExpandedTrip = !IsExpandedTrip;">
      <img src="assets/img/svg images/lr.svg" alt="" height="40">
      <span style="    margin-left: 6%;">Track</span>
      <span class="material-icons lefbar_arrow" *ngIf="!IsExpandedTrip">
        keyboard_arrow_right
      </span>
      <span class="material-icons lefbar_arrow" *ngIf="IsExpandedTrip">
        keyboard_arrow_down
      </span>
    </a>
    <div *ngIf="IsExpandedTrip ">
      <ul class="navGroupsNested">
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/trips/track/assignment']" routerLinkActive="active">
            <span class="nestPadding">Pending Assignment</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/trips/track/placement']" routerLinkActive="active">
            <span class="nestPadding">Pending Dispatch</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/trips/track']" routerLinkActive="active">
            <span class="nestPadding">Trip Tracking </span>
          </a>
        </li>

        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/trips/track/POD']" routerLinkActive="active">
            <span class="nestPadding">POD Tracking</span>
          </a>
        </li>

        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/trips/track/Bill']" routerLinkActive="active">
            <span class="nestPadding">Pending Bill</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/trips/track/MR']" routerLinkActive="active">
            <span class="nestPadding">Outstandings</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/trips/track/payments']" routerLinkActive="active">
            <span class="nestPadding">Pending Payments</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1, 2, 4, 5]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/tripStatusTrackings']" routerLinkActive="active">
            <span class="nestPadding">Trip History</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1, 2, 4, 5]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/thcStatusTrackings']" routerLinkActive="active">
            <span class="nestPadding">THC History</span>
          </a>
        </li>
      </ul>
    </div>
  </li>
  <li *ngIf="checkRole([10, 1, 2, 4, 5]) && !userIsLE()" (click)="toggleDrawer()">
    <a [routerLink]="['/bids/list']">
      <img src="assets/img/svg images/booking.svg" alt="" height="40">
      <span style="    margin-left: 6%;">Bids</span>
    </a>
  </li>
  <li *ngIf="checkRole([10, 1]) && !userIsLE()">
    <a (click)="IsExpandedTripRequests = !IsExpandedTripRequests">
      <img src="assets/img/svg images/booking.svg" alt="" height="40">
      <span style="    margin-left: 6%;">Trip Requests</span>
      <span class="material-icons lefbar_arrow" *ngIf="!IsExpandedTripRequests">
        keyboard_arrow_right
      </span>
      <span class="material-icons lefbar_arrow" *ngIf="IsExpandedTripRequests">
        keyboard_arrow_down
      </span>
    </a>
    <div *ngIf="IsExpandedTripRequests ">
      <ul class="navGroupsNested">
        <li *ngIf="checkRole([10, 1])  && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/1/driverTrips']" routerLinkActive="active">
            <span class="nestPadding">Drivers</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])  && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/1/driverTrips/partners']" routerLinkActive="active">
            <span class="nestPadding">Partners</span>
          </a>
        </li>
      </ul>
    </div>
  </li>

  <li *ngIf="checkRole([10, 1])  && !userIsLE()">
    <a (click)="toggleReport($event)">
      <img src="assets/img/svg images/report.svg" style="padding-left: 6px;" alt="" height="30">
      <span style="padding-left: 3px;" style="    margin-left: 6%;">Reports</span>
      <span class="material-icons lefbar_arrow" *ngIf="!IsExpandedReports">
        keyboard_arrow_right
      </span>
      <span class="material-icons lefbar_arrow" *ngIf="IsExpandedReports">
        keyboard_arrow_down
      </span>
    </a>
    <div *ngIf="IsExpandedReports ">
      <ul class="navGroupsNested">
        <li *ngIf="checkRole([10, 1])  && !userIsLE()">
          <a [routerLink]="['/reports/salesByCustomer']" [queryParams]="{time_period:'MTD'}" routerLinkActive="active">
            <span class="nestPadding">Sales</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])  && !userIsLE()">
          <a [routerLink]="['/reports/placementsByTrip']" [queryParams]="{time_period:'Yesterday'}"
            routerLinkActive="active">
            <!-- <img src="assets/img/svg images/report.svg" alt="" height="30"> -->
            <span class="nestPadding">Placement</span>
          </a>
        </li>

        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/reports/podByVehicleNotReceived']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <!-- <img src="assets/img/svg images/report.svg" alt="" height="30"> -->
            <span class="nestPadding">POD Not Received(CC)</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/reports/podByVehicleDelayed']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <!-- <img src="assets/img/svg images/report.svg" alt="" height="30"> -->
            <span class="nestPadding">Vehicle Delayed(CC)</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/reports/podByVehicleUntraceable']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <!-- <img src="assets/img/svg images/report.svg" alt="" height="30"> -->
            <span class="nestPadding">Vehicle Untraceable(CC)</span>
          </a>
        </li>

        <li *ngIf="checkRole([10, 1])  && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/reports/podByCustomer']" routerLinkActive="active">
            <!-- <img src="assets/img/svg images/report.svg" alt="" height="30"> -->
            <span class="nestPadding">POD / Billing</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])  && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/reports/aging']" routerLinkActive="active">
            <!-- <img src="assets/img/svg images/report.svg" alt="" height="30"> -->
            <span class="nestPadding">Aging</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])  && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/reports/usage']" routerLinkActive="active">
            <!-- <img src="assets/img/svg images/report.svg" alt="" height="30"> -->
            <span class="nestPadding">Usage</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/dashboard/new']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <!-- <img src="assets/img/svg images/report.svg" alt="" height="30"> -->
            <span class="nestPadding">Dashboard(Test)</span>
          </a>
        </li>

      </ul>
    </div>
  </li>
  <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
    <a [routerLink]="['/1/invoices/list']" routerLinkActive="active">
      <!-- <i *ngIf="displayIcon" class="fa fa-user" aria-hidden="true"></i> -->
      <img src="assets/img/svg images/booking.svg" alt="" height="40">
      <span style="    margin-left: 6%;">Invoices</span>
    </a>
  </li>
  <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
    <a [routerLink]="['/payments/list']" routerLinkActive="active">
      <!-- <i *ngIf="displayIcon" class="fa fa-user" aria-hidden="true"></i> -->
      <img src="assets/img/svg images/booking.svg" alt="" height="40">
      <span style="    margin-left: 6%;">Receipts</span>
    </a>
  </li>
  <li>
    <a [routerLink]="['/thcPayments/thcPayments/list']">
      <img src="assets/img/svg images/payment.svg" alt="" height="40">
      <span style="margin-left:8px;"> Payments</span>
    </a>
  </li>
  <li *ngIf="checkRole([10, 1])">
    <a (click)="$event.preventDefault(); IsExpandedAlgo = !IsExpandedAlgo;">
      <img src="assets/img/svg images/lr.svg" alt="" height="40">
      <span style="    margin-left: 6%;">Algos</span>
      <span class="material-icons lefbar_arrow" *ngIf="!IsExpandedAlgo">
        keyboard_arrow_right
      </span>
      <span class="material-icons lefbar_arrow" *ngIf="IsExpandedAlgo">
        keyboard_arrow_down
      </span>
    </a>
    <div *ngIf="IsExpandedAlgo">
      <ul class="navGroupsNested">
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/drivers/document-details']" routerLinkActive="active">
            <!-- <img src="assets/img/svg images/lr.svg" alt="" height="30"> -->
            <span class="nestPadding">OCR</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/drivers/fatigue']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <!-- <img src="assets/img/svg images/lr.svg" alt="" height="30"> -->
            <span class="nestPadding">Driver Fatigue</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1])" (click)="toggleDrawer()">
          <a [routerLink]="['/drivers/tyre-health']" routerLinkActive="active">
            <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
            <!-- <img src="assets/img/svg images/lr.svg" alt="" height="30"> -->
            <span class="nestPadding">Type Inspection</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/pricePredict/']" routerLinkActive="active">
            <!-- <img src="assets/img/svg images/lr.svg" alt="" height="30"> -->
            <span class="nestPadding">Suggest Price</span>
          </a>
        </li>
      </ul>
    </div>
  </li>
  <li *ngIf="checkRole([10, 1, 2, 4, 5]) && !userIsLE()">
    <a (click)="toggle2()" routerLinkActive="active">
      <!-- <i *ngIf="displayIcon" class="fa fa-tasks" aria-hidden="true"></i> -->
      <img src="assets/img/svg images/booking.svg" alt="" height="40">
      <span style="    margin-left: 6%;">Manage</span>
      <span class="material-icons lefbar_arrow" *ngIf="!IsExpanded2">
        keyboard_arrow_right
      </span>
      <span class="material-icons lefbar_arrow" *ngIf="IsExpanded2">
        keyboard_arrow_down
      </span>
    </a>
    <div *ngIf="IsExpanded2 ">
      <ul class="navGroupsNested">

        <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/customers/list']" routerLinkActive="active">
            <!-- <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i> -->
            <!-- <img src="assets/img/svg images/customer.svg" alt="" height="30"> -->
            <span class="nestPadding">Customers</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1, 3 ]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/contracts']" routerLinkActive="active">
            <!-- <i *ngIf="displayIcon" class="fa fa-credit-card" aria-hidden="true"></i> -->
            <!-- <img src="assets/img/svg images/booking.svg" alt="" height="30"> -->
            <span class="nestPadding">Contracts</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/partners']" routerLinkActive="active">
            <!-- <img src="assets/img/svg images/customer.svg" alt="" height="30"> -->
            <span class="nestPadding">Partners</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/partnerBanks/partnerBanks']" routerLinkActive="active">
            <!-- <img src="assets/img/svg images/customer.svg" alt="" height="30"> -->
            <span class="nestPadding">Partner Banks</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1, 2, 4, 5]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/trucks']" routerLinkActive="active">
            <!-- <i *ngIf="displayIcon" class="fa fa-truck" aria-hidden="true"></i> -->
            <!-- <img src="assets/img/svg images/truck.svg" alt="" height="30"> -->
            <span class="nestPadding">Trucks</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1, 4, 5]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/drivers']" routerLinkActive="active">
            <!-- <i *ngIf="displayIcon" class="fa fa-id-card-o" aria-hidden="true"></i> -->
            <!-- <img src="assets/img/svg images/drivers.svg" alt="" height="30"> -->
            <span class="nestPadding"> Drivers </span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && !userIsLE()" (click)="toggleDrawer()">
          <a [routerLink]="['/users/list']" routerLinkActive="active">
            <!-- <i *ngIf="displayIcon" class="fa fa-user" aria-hidden="true"></i> -->
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Users</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1]) && !userIsLE()">
          <a [routerLink]="['/usersGroup/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Bid Users Group</span>
          </a>
        </li>
      </ul>
    </div>
  </li>
  <li *ngIf="checkRole([10])">
    <a (click)="toggle3()" routerLinkActive="active">
      <!-- <i *ngIf="displayIcon" class="fa fa-tasks" aria-hidden="true"></i> -->
      <img src="assets/img/svg images/profile.svg" alt="" height="40">
      <span style="    margin-left: 6%;">Admin & Test</span>
      <span class="material-icons lefbar_arrow" *ngIf="!IsExpanded3">
        keyboard_arrow_right
      </span>
      <span class="material-icons lefbar_arrow" *ngIf="IsExpanded3">
        keyboard_arrow_down
      </span>
    </a>
    <div *ngIf="IsExpanded3">
      <ul class="navGroupsNested">
        <li (click)="toggleDrawer()">
          <a [routerLink]="['/userDeviceTokens/list']" routerLinkActive="active">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Device Tokens</span>
          </a>
        </li>
        <li (click)="toggleDrawer()">
          <a [routerLink]="['/userDeviceData/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Device Data</span>
          </a>
        </li>
        <li (click)="toggleDrawer()">
          <a [routerLink]="['/audit/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Audit</span>
          </a>
        </li>
        <li *ngIf="checkRole([10, 1, 2, 4, 5]) && !userIsLE()">
          <a [routerLink]="['/usersGroup/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Bid Users Group</span>
          </a>
        </li>

        <li>
          <a [routerLink]="['/dropdowns/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Dropdowns</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/roles/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Roles</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/failedJobs/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Failed Jobs</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/forecastMasterData/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Forecast Master Data</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/forecastOilPrice/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Forecast Oil Price</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/forecastTruckTypeMapping/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Forecast Truck Type Mapping</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/jobs/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Jobs</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/notifications/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Notifications</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/pushSubscriptions/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">pushSubscriptions</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/quotes/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Quotes</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/states/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">States</span>
          </a>
        </li>


        <li>
          <a [routerLink]="['/webexpressActivityLog/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Webexpress Activity Log</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/queue/list']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">Queue</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/tenew/edit']">
            <!-- <img src="assets/img/svg images/profile.svg" alt="" height="30"> -->
            <span class="nestPadding">tenew</span>
          </a>
        </li>
        <!-- <li>
        <a [routerLink]="['/pricing_contract/list']" routerLinkActive="active">
          <i *ngIf="displayIcon" class="fa fa-credit-card" aria-hidden="true"></i>
          <span>Pricing & Contract</span>
        </a>
      </li> -->
        <!-- <li>
        <a [routerLink]="['/partner-contacts-list/list']" routerLinkActive="active">
          <i *ngIf="displayIcon" class="fa fa-compress" aria-hidden="true"></i>
          <span>Partner Contacts List</span>
        </a>
      </li> -->

        <!-- <li>
        <a [routerLink]="['/consignmentNotes']" routerLinkActive="active">
          <i *ngIf="displayIcon" class="fa fa-sticky-note-o" aria-hidden="true"></i>
          <span>Consignment Notes</span>
        </a>
      </li> -->
        <!-- <li>
        <a [routerLink]="['/myconsignmentNotes']" routerLinkActive="active">
          <i *ngIf="displayIcon" class="fa fa-sticky-note-o" aria-hidden="true"></i>
          <span>My Consignment Notes</span>
        </a>
      </li> -->
        <!-- <li>
        <a [routerLink]="['/consignmentNotesPrint/view']" routerLinkActive="active">
          <i *ngIf="displayIcon" class="fa fa-print" aria-hidden="true"></i>
          <span>Consignment Notes Print</span>
        </a>
      </li> -->
        <!-- <li>
        <a [routerLink]="['/users']" routerLinkActive="active">
          <i *ngIf="displayIcon" class="fa fa-users" aria-hidden="true"></i>
          <span>Users</span>
        </a>
      </li> -->
        <!-- <li>
        <a [routerLink]="['/dropdowns']" routerLinkActive="active">
          <i *ngIf="displayIcon" class="fa fa-list-ul" aria-hidden="true"></i>
          <span>Dropdowns</span>
        </a>
      </li> -->
      </ul>
    </div>
  </li>
</ul>

<div class="text-center p-t-0 bottomImg">
  <img src="assets/img/menu-footer.png" style="width: 90%;" />
  <!-- display: none; -->
</div>
