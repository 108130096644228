import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/core/services/common.service';
import { UsersService } from '../../manage/users/services/users.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Users } from '../../manage/users/users';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  providers: [UsersService, ]
})
export class SignupComponent implements OnInit {
  isSubmitSuccessful:boolean = false;
  hidePassword = true;
  constructor(
    private _titleService: Title,
    private commonService:CommonService,
    private usersService: UsersService,
    private _formBuilder: FormBuilder,
    private router: Router) { }
  roleList : any[];
  signUpFormGroup: FormGroup;
  errorMessage:string = null;
  successMessage:string = null
  ngOnInit() {
    this.signUpFormGroup = this._formBuilder.group({
      name:['',[Validators.required,Validators.minLength(3)]],
      /* last_name: ['',[Validators.required,Validators.minLength(3)]], */
      mobile_no: ['',[Validators.required,Validators.pattern('[6-9]{2}[0-9]{8}')]],
      email: ['',[Validators.required,Validators.email]],
      password: ['',[Validators.required,Validators.minLength(6)]],
      confirm_password: ['',[Validators.required,Validators.minLength(6)]],
      roles_id: [3,[Validators.required,Validators.min(1)]],
      termsCheck:[false]
    });
    this.usersService.getRoleList().subscribe(response=>{
      this.roleList = response.data;
      
    },error => {
      this.roleList = [{'id' : 3, 'name' : 'Customer'}];
    });
  }
  selectRole(id){
    this.signUpFormGroup.patchValue({
      roles_id:id
    });
  }
  submit(data){
    this.errorMessage = null;
    this.successMessage = null;
    if(data.password == data.confirm_password){
      let user = new Users(data);
      this.usersService.userSignUp(user).subscribe(response=>{
        if(response.status == 200) {
          this.isSubmitSuccessful = true;
        }
        else {
          alert(response.message );
        }
      },
      error => {
        alert(error);
      });
    }else{
      alert('password & confirm_password not match');
    }
  }
}
