import {Directive, ElementRef, HostListener} from '@angular/core';

const DISABLE_TIME = 3000;

@Directive({
    selector: 'button[noDoubleClickDisable]'
})
export class NoDblClickDisableDirective {
    constructor(private elementRef: ElementRef) { }
    @HostListener('click', ['$event'])
    clickEvent() {
        //
        //
        this.elementRef.nativeElement.setAttribute('disabled', 'true');
        setTimeout(() => this.elementRef.nativeElement.removeAttribute('disabled'), DISABLE_TIME);
    }
}
