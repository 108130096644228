import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "./http-service.service";
import { ResponseModel } from "../model/response";
import { Constants } from "../constants";
import { AppSettings } from "../config";
import { StorageService } from "./storage.service";
@Injectable()
export class ConfigService {
  constructor(private serviceHttp: HttpService) {}
  // getList(): Observable<ResponseModel> {
  //   return this.serviceHttp.getResponse('dropdowns/en', 'GET');
  // }
  getDropDowns(): Promise<any> {
    Constants.DROP_DOWN_LIST = new Array();
    Constants.DROP_DOWN_HASH = new Array();
    const promise = this.serviceHttp
      .getResponse("dropdowns/en", "GET")
      .toPromise()
      .then((response) => {
        response.data.forEach((element) => {
          Constants.DROP_DOWN_LIST.push(element);
          if (Constants.DROP_DOWN_HASH[element.dd_name.toUpperCase()]) {
            Constants.DROP_DOWN_HASH[element.dd_name.toUpperCase()].push(
              element
            );
          } else {
            Constants.DROP_DOWN_HASH[element.dd_name.toUpperCase()] = new Array(
              element
            );
          }
        });
      })
      .catch((error) => {});
    return promise;
  }
  initializeConfig(): Promise<any> {
    //
    //

    const promise = this.serviceHttp
      .getAsset("assets/config/config.json")
      .toPromise()
      .then((config) => {
        // do stuff with the config
        //

        AppSettings.SERVER_ENDPOINT = config["apiServer"].SERVER_URL;
        AppSettings.API_ENDPOINT = AppSettings.SERVER_ENDPOINT + "api/";
        AppSettings.IMAGE_ENDPOINT =
          AppSettings.SERVER_ENDPOINT + "api/images/";
        //
        Constants.DROP_DOWN_LIST = new Array();
        Constants.DROP_DOWN_HASH = new Array();
        return this.getDropDowns();
      })
      .catch((error) => {

        // throw error;
      });
    return promise;
  }
}
