<div class="main-bg" style="padding:0px 40px;">
    <div class=" card main-card" style="box-shadow: 1px 1px 4px 2px #00000014;">
        <div class="row" style="margin: 0px;padding: 0px;">
            <div class="col-2" style="background:#12afec14;height: 80vh;">
&nbsp;
<div class="profile-img">
    <img src="assets/img/user.png" alt=""  style="
    position: absolute;
    right: -50px;
    border: 1px solid;
    background: #fff;
    top: 30px;
">
</div>
            </div>
            <div class="col-10" style="padding: 35px 90px;">
<h1><b>Edit Profile</b></h1>
<p>Click on the profile picture to upload new one.</p>
            </div>
        </div>
    </div>
</div>
