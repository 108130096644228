import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http-service.service'
import { ResponseModel } from '../model/response'
import { Constants } from '../constants';
import { AppSettings } from '../config';
@Injectable()
export class DropdownsService {
  constructor(private serviceHttp: HttpService) { }
  getList(): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('dropdowns/en', 'GET');
  }
  getDropDowns(): Promise<any> {
    Constants.DROP_DOWN_LIST = new Array();
    Constants.DROP_DOWN_HASH = new Array();
    const promise = this.serviceHttp.getResponse('dropdowns/en', 'GET').toPromise().then(response => {
      response.data.forEach(element => {
        Constants.DROP_DOWN_LIST.push(element);
        if(Constants.DROP_DOWN_HASH[element.dd_name.toUpperCase()]){
          Constants.DROP_DOWN_HASH[element.dd_name.toUpperCase()].push(element);
        }
        else{
          Constants.DROP_DOWN_HASH[element.dd_name.toUpperCase()] = new Array(element);
        }
      });
    }).catch(error => {
    });
    return promise;
  }


}
