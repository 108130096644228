import { Component, OnInit, Output, Input, EventEmitter, OnChanges } from '@angular/core';
import {Dropdowns} from './dropdowns'
import { Constants } from '../../core/constants';
import { DropdownsService } from '../../core/services/dropdowns.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DropdownRelations } from '../../core/dropdownRelations';
@Component({
  selector: 'app-dropdowns',
  templateUrl: './dropdowns.component.html',
  styleUrls: ['./dropdowns.component.css']
})
export class DropdownsComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() refType: Array<string>;   //used to map with other dropdown to identify dropdown type
  @Input() refId: Array<string>;   //used to map with other dropdown to identify option using dd_key
  @Input() placeholder: string;
  @Input() multiple = false;
  @Input() diabled = false;
  @Input() required: boolean;
  @Input() filter: any;
  @Output() onSelect = new EventEmitter<any>();
  @Output() onTouched = new EventEmitter<boolean>(false);
  _isValid :boolean;
  _isTouched :boolean;
  _requiredText = "";
  _invalidClass = "";
  _invalidClassSelect = "";
  private _isInValid: boolean;
  constructor(private dropdownsService:DropdownsService,private _formBuilder: FormBuilder) {
  }
  @Input() selected :any;
  list : Dropdowns[];
  ngOnInit() {
    if(!this.placeholder){
      this.placeholder = this.type;
    }
    if(this.required){
      this._requiredText = "required";
    }
  }

  checkRelation(dd_key: string){
    let relationsMapping = DropdownRelations.data;
    let hasRelation = true;
    let that = this;
    if(this.refId && this.refType && relationsMapping){
      this.refType.forEach(function(type, index){
        if(relationsMapping[type]){
          if(relationsMapping[type][that.refId[index]]){
            if(relationsMapping[type][that.refId[index]][that.type]){
              if(relationsMapping[type][that.refId[index]][that.type].indexOf(dd_key) >= 0){
                hasRelation = hasRelation && true;
              }else{
                hasRelation = false;
              }
            }
          }
        }
      }, that);
    }else{
      hasRelation = true;
    }
    return hasRelation;
  }

  generateListData(){
    //
    this.list = new Array();

    if(Constants.DROP_DOWN_HASH && Constants.DROP_DOWN_HASH[this.type.toUpperCase()]){
      Constants.DROP_DOWN_HASH[this.type.toUpperCase()].forEach(element => {
        if (element.dd_name.toUpperCase().indexOf(this.type.toUpperCase()) === 0) {
          if(this.checkRelation(element.dd_key)){
            if(this.filter){

              if(this.filter.indexOf(element.dd_key) > -1){
                this.list.push(element);
              }
            }
            else {
              this.list.push(element);
            }

          }
        }
      });
      //
    }
    else if(Constants.DROP_DOWN_LIST){
      Constants.DROP_DOWN_LIST.forEach(element => {
        if (element.dd_name.toUpperCase().indexOf(this.type.toUpperCase()) === 0) {
          if(this.filter){
            if(this.filter.indexOf(element.dd_key) > -1){
              this.list.push(element);
            }
          }
          else {
            this.list.push(element);
          }

        }
      });
    }
      if(this.type == 'state' || this.type == 'state_cd'){
        this.list.push(new Dropdowns({'dd_key': 'Eastern Development Region', 'dd_value': 'Eastern Development Region'}));
        this.list.push(new Dropdowns({'dd_key': 'Central Development Region', 'dd_value': 'Central Development Region'}));
        this.list.push(new Dropdowns({'dd_key': 'Western Development Region', 'dd_value': 'Western Development Region'}));
        this.list.push(new Dropdowns({'dd_key': 'Mid-Western Development Region', 'dd_value': 'Mid-Western Development Region'}));
        this.list.push(new Dropdowns({'dd_key': 'Far-Western Development Region', 'dd_value': 'Far-Western Development Region'}));

      }
      if(this.type == 'truck_type_cd') {
        this.list.sort(function(a, b){
          if (a.dd_value_short < b.dd_value_short){
            return -1;
          }
          if (a.dd_value_short > b.dd_value_short) {
            return 1;
          }
          return 0;
      });
      //
      }
    }
  ngOnChanges(){
    //
    this.generateListData();
  }
  selectId() {
    //
    this.onSelect.emit(this.selected);
  }
  setTouchedSelf(){
    //
    this._isTouched = true;
    this.touched = true;
  }
  @Input()
  set valid(isValid:boolean){
    //

    this._isValid = isValid;
    if(!this._isValid){
     // this._invalidClass=" mat-input-invalid mat-form-field-invalid";
     // this._invalidClassSelect="mat-select-invalid";
     //
    }

  }
  @Input()
  set invalid(isInValid:boolean){
    //

    this._isInValid = isInValid;
    if(this._isValid){
    //  this._invalidClass=" mat-input-invalid mat-form-field-invalid";
    //  this._invalidClassSelect="mat-select-invalid";

    }

  }
  @Input()
  set touched(_isTouched:boolean){

    //
    this.onTouched.emit(_isTouched);
    this._isTouched = _isTouched;
    if(this._isTouched && this._isInValid){
      this._invalidClass="mat-input-invalid mat-form-field-invalid";
      this._invalidClassSelect="mat-select-invalid";
      //
    }
    if(this._isTouched && !this._isTouched){
      //
    }

  }
  @Input()
  set markAsTouched(opts: any){
    //
    this._isTouched = true;
    this.touched = true;
    //
  }
}
