import { Roles } from '../roles/roles';
import * as appGlobals from '../../shared/core/globalfunctions';
import { Validators } from '@angular/forms';
import { CustomerContacts } from '../customerContacts/customerContacts';
import { PartnerContacts } from '../partnerContacts/partnerContacts';
import { Drivers } from '../drivers/drivers';
export class Users {
    id : number;
    uuid : string;
    name : string;
    nick_name : string;
    email : string;
    mobile_no : string;
    password : string;
    avatar_type : string;
    avatar_location : string;
    active : number;
    email_confirmation_cd : string;
    email_confirmed : number;
    mobile_confirmation_cd : string;
    mobile_confirmed : number;
    timezone : string;
    remember_token : string;
    password_changed_at : Date;
    roles_id :number;
    roles :Roles;
    rating:number;
    company_name: string;
    driving_license_scan: string;
    street: string;
    city: string;
    state: string;
    pincode: number;
    location_place_id: string;
    location_lat: number;
    location_long: number;
    device_id: string;
    device_type: string;
    login: string;
    // TODO: get company name
    // contacts: CustomerContacts;
    // partnerContacts: PartnerContacts;
    // driver: Drivers;
    last_login_at: String;
    otp: number;
    managers_id: number;
    territory_cd: string;
    branch_cd: string;
    telenity_enabled: number;
    work_area_cd: Array<string>;
    managers_name: string;
    mobile_provider_cd: string;
    telenity_consent: string;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : Users[] {
        let users : Users[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                users.push(new Users(json));
            }
        }
        return users;
    }
    fill(json: any){

      if (json != null) {
        if(json.id){
          this.id = json.id;
        }
        this.uuid = json.uuid;
        this.name = json.name;
        this.nick_name = json.nick_name;
        this.email = json.email;
        this.mobile_no = json.mobile_no;
        if(json.password){
          this.password = json.password;
        }
        this.avatar_type = json.avatar_type;
        this.avatar_location = json.avatar_location;
        this.active = json.active;
        this.email_confirmation_cd = json.email_confirmation_cd;
        this.email_confirmed = json.email_confirmed;
        this.mobile_confirmation_cd = json.mobile_confirmation_cd;
        this.mobile_confirmed = json.mobile_confirmed;
        this.timezone = json.timezone;
        this.remember_token = json.remember_token;
        if (json.password_changed_at != null) {
            this.password_changed_at = new Date(json.password_changed_at);
        }
        this.roles_id = json.roles_id;
        this.company_name = json.company_name;
        this.driving_license_scan = json.driving_license_scan;
        this.street = json.street;
        this.city = json.city;
        this.state = json.state;
        this.pincode = json.pincode;
        this.device_id = json.device_id;
        this.device_type = json.device_type;
        this.location_place_id = json.location_place_id;
        this.location_lat = json.location_lat;
        this.location_long = json.location_long;
        this.login = json.login;

        if(json.contacts!= null){
            // this.contacts = new CustomerContacts(json.contacts);
        }
        if(json.partnerContacts!= null){
            // this.partnerContacts = new PartnerContacts(json.partnerContacts);
        }
        if(json.driver!= null){
            // this.driver = new Drivers(json.driver);
        }
        if(json.roles!= null){
            this.roles = new Roles(json.roles);
        }
        this.last_login_at = json.last_login_at;
        this.otp = json.otp;
        this.territory_cd = json.territory_cd;
        this.managers_id = json.managers_id;
        this.branch_cd = json.branch_cd;

        if(json.work_area_cd != null && Array.isArray(json.work_area_cd)){
          this.work_area_cd = json.work_area_cd;

        }

        else if(json.work_area_cd != null){
          if (typeof json.work_area_cd  === 'string' || json.work_area_cd  instanceof String) {
            this.work_area_cd = [json.work_area_cd];
          }
          else {
            this.work_area_cd = JSON.parse(json.work_area_cd); //json.work_area_cd.split(",");
          }

        }
        this.telenity_enabled = json.telenity_enabled;
        this.managers_name = json.managers_name;
        this.mobile_provider_cd = json.mobile_provider_cd;
        if(this.mobile_provider_cd != null) {
          this.mobile_provider_cd = this.mobile_provider_cd.toUpperCase();
        }
        this.telenity_consent = json.telenity_consent;
      }
    }
}
export let usersFormControls ={
  name: ['', [Validators.required, Validators.minLength(3)]],
  nick_name: ['', []],
  mobile_no: ['', [Validators.required, Validators.min(1000000000), Validators.max(9999999999)]],
  // email: ['', [appGlobals.customEmailValidator]],
  email: ['', [appGlobals.customEmailValidator]],
  password: ['', []],
  active: [false,[]],
  telenity_enabled: [false,[]],
  managers_id: ['', []],
  managers_name: ['', []],
  territory_cd: ['', []],
  branch_cd: ['', []],
  work_area_cd: ['', []],
  id: [null, []],
  roles_id: ['', []],
  otp: ['',[]],
  mobile_confirmed: [false, []],
  mobile_provider_cd: ['', []],
  avatar_location: ['', []],
  };
