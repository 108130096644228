import { Directive, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appUppercase]'
})
export class UppercaseDirective implements OnInit {

  private readonly destroySubject$ = new Subject<void>();

  constructor(
    private readonly ngControl: NgControl
  ) { }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  ngOnInit(): void {
    this.ngControl.control.valueChanges.pipe(
      takeUntil(this.destroySubject$)
    ).subscribe(value => {
      if(value && value != null && (typeof value == 'string' || value instanceof String)){
        const newValue = value.toUpperCase();
        this.ngControl.control.setValue(newValue, {
          emitEvent: false,
          emitModelToViewChange: true,
          emitViewToModelChange: false
        });

      }
    });
  }
}
