import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  NgZone,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Constants } from '../../core/constants';
import { DropdownsService } from '../../core/services/dropdowns.service';
// import { MapsAPILoader } from '@agm/core';
// import { } from 'googlemaps';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
declare var google: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  @Output() onSelect = new EventEmitter<any>();
  @Output() touched = new EventEmitter<boolean>(false);
  @Input() placeholder = '';
  @Input() addressType = ''; // 'address';
  @Input() required: boolean;
  // @Input() formControlName = '';
  _isTouched: boolean;
  _isValid: boolean;
  _requiredText = '';
  _invalidClass = '';
  _forControlText = '';
  @ViewChild('customMapLocation', { static: true }) public customMapLocation: ElementRef;
  constructor(
    // private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _formBuilder: FormBuilder
  ) {}
  ngOnInit() {
    //TODO: implement a way to force load google maps js.
    if (typeof google === 'object' && typeof google.maps === 'object') {
          // const options = { types: [this.addressType,  'postal_code'],
          // const options = { types: [this.addressType],
          let options = {};

          if (this.addressType !== '') {
            options = {
              // componentRestrictions: { country: ['IN', 'NP', 'BT'] },
              types: [this.addressType],
              language: 'en'
            };
          } else {
            options = {
              // componentRestrictions: { country: ['IN', 'NP', 'BT'] },
              language: 'en'
            };
          }
          const sessionToken = new google.maps.places.AutocompleteSessionToken();
          options['session_token'] = sessionToken;
          options['sessionToken'] = sessionToken;
          options["fields"] = ["address_component","formatted_address","name","geometry"];
          //
          //
          const homeLocation = new google.maps.places.Autocomplete(
            this.customMapLocation.nativeElement,
            options
          );
          homeLocation.addListener('place_changed', () => {
            this.ngZone.run(() => {
              const place: google.maps.places.PlaceResult = homeLocation.getPlace();
              //
              // if (place.geometry === undefined || place.geometry === null) {
              //

              //   return;
              // }
              this.onSelect.emit(place);
            });
            this.ngZone.onError.subscribe(e => {

            });
          });
        }
        else {
          alert('Google maps is not loaded. Please check your internet connection and refresh this page.');
        }
  }

  @Input()
  set value(message: string) {
    if (message && this.customMapLocation) {
      this.customMapLocation.nativeElement.value = message;
    }
  }
  @Input()
  set formControlName(formCName: string) {}
  setTouched() {
    this.touched.emit(true);
  }
  @Input()
  set valid(isValid: boolean) {
    if (this._isTouched && !isValid) {
      this._invalidClass = 'mat-input-invalid mat-form-field-invalid';
    }
  }
  @ViewChild('customMapLocation', { static: true }) set content(content: ElementRef) {
    this.customMapLocation = content;
  }
}
