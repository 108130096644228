<header class="header" id="header">
  <div class="logo pull-left">
    <a href="#">
      <img src="assets/img/glasswing_logo.svg">
    </a>
  </div>
</header>
<div class="formWhiteBox ">

  <div class="row justify-content-md-center">
    <div class="col-md-6 d-flex justify-content-center" >
      <!-- <div class="sign-logo text-center">
        <a href="#">
          <img src="assets/img/glasswing_logo.svg">
        </a>
      </div> -->
      <div class="loginMainDiv">

        <!-- <button class="login-with-google" color="secondary" *ngIf="authIsLoaded && !isLoggedIn" (click)="signInByGoogle()">Sign In With Google</button> -->
        <div class="row mBtm15">
          <div class="col-sm-12">
            <!-- *ngIf="authIsLoaded" -->
            <ng-container>
              <!-- <button class="login-with-google" color="secondary" *ngIf="authIsLoaded && !isLoggedIn"
                (click)="signInByGoogle()">Sign In With Google</button> -->
              <!-- <button class="login-with-google" color="secondary" *ngIf="authIsLoaded && isLoggedIn"
                (click)="signOut()">Sign Out</button> -->
            </ng-container>
            <!-- <h2 class="login-with-google" *ngIf="authIsLoaded && isLoggedIn"> Signed in as {{user.name}} </h2>` -->
            <!-- <h2 class="login-with-google" *ngIf="authIsLoaded && isLoggedIn && user.name"> Signed in as {{user.name}} -->
            <!-- </h2> -->
          </div>
        </div>
        <div class="row mBtm15">
          <div class="col-sm-12">
            <ng-container>
              <button class="login-with-google login-with-office" color="secondary" *ngIf="!isLoggedIn" (click)="signInByOffice()">
                  Sign In With Office</button>

              <button class="login-with-google login-with-office" color="secondary" *ngIf="isLoggedIn" (click)="signOut()">Sign
                Out</button>
            </ng-container>
            <!-- <h2 class="login-with-google" *ngIf="authIsLoaded && isLoggedIn && user.name"> Signed in as {{user.name}}
            </h2> -->
          </div>
        </div>
        <div class="row m-t-40">
          <div class="col-sm-12">
            <form class="example-form" [formGroup]="loginFormGroup" style="text-align: left;">
          <span class="headText">Or Use E-mail / Mobile</span>
          <p *ngIf="errorMessage" class="redFont">{{errorMessage}}</p>
          <!-- <a href="#" class="login-with-google">Log in with Google</a> -->
          <!-- <div class="or">or</div> -->

            <mat-form-field class="w-100">
              <input type="text" matInput placeholder="Email or Mobile" formControlName="login">
              <mat-error *ngIf="!loginFormGroup.get('login').valid && this.loginFormGroup.get('login').touched">
                Please enter your registered email or mobile number.
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-100">
              <input [type]="hidePassword ? 'password' : 'text'" matInput placeholder="Password"
                formControlName="password">
                <img src="assets/img/pass.svg" alt="pass icon" [ngClass]="{'passIcon': hidePassword, 'activePass': !hidePassword}" (click)="hidePassword = !hidePassword">
              <!-- <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' :
              'visibility_off'}}</mat-icon> -->
              <mat-error *ngIf="!loginFormGroup.get('password').valid && loginFormGroup.get('password').touched">
                <span *ngIf="loginFormGroup.get('password').errors.required">Password is required.</span>
                <span *ngIf="loginFormGroup.get('password').errors.minlength">Password must have 6 characters.</span>
              </mat-error>
              <!--
				   <input type="password" matInput placeholder="Password" formControlName="password">
				    <mat-error *ngIf="!loginFormGroup.get('password').valid && this.loginFormGroup.get('password').touched">
					  Password is Not Valid
					</mat-error> -->
            </mat-form-field>

        <!-- </div>
      </div>
        <div class="row mBtm10 mTop20">
          <div class="col-sm-12 text-center"> -->
            <mat-checkbox style="color: #343434;
            opacity: 55%;">Remember Me</mat-checkbox>
          <!-- </div>
        </div>

        <div class="row mBtm15"> -->
          <div >
            <!--<button mat-raised-button color="primary" [disabled]="!loginFormGroup.valid" (click)="submit(loginFormGroup.value)">Sign In</button>-->
            <button class="login-with-gw" mat-raised-button color="primary" style="    background-color: #12AFEC;
            border: #12AFEC;" (click)="submit(loginFormGroup.value)">Sign
              In</button>
            <!-- <div class="g-signin2" data-onsuccess="onSignIn">Sign In with google original</div> -->
          </div>
        </form>
        </div>
        </div>

        <div class="row mBtm15">
          <div class="col-sm-12 text-center sign-forg" style="    padding-top: 10px;">
            <a [routerLink]="['/user/forgot-password']" routerLinkActive="active" class="reset-password">Forgot
              password?</a>
          </div>
          <!--<div class="col-sm-6 text-right sign-rest">
					  <a  [routerLink]="['/user/reset-password']" routerLinkActive="active" class="reset-password">Reset password?</a>
					</div>-->
        </div>
        <div class="row signup-link text-center">Need an account? <a [routerLink]="['/user/signup']"
            routerLinkActive="active">Sign
            up</a></div>

      </div>
</div>
    </div>
<!-- </form> -->
</div>
<ng-template #territoryInfo>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Update work information</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="example-form forg-rest-pass" [formGroup]="usersFormGroup">
      <div class="row">
        <div class="col-sm-3">
          <mat-form-field class="w-100">
            <input required type="text" placeholder="Employee Responsible or Manager" matInput [matAutocomplete]="autop"
              formControlName="managers_name">
            <mat-autocomplete #autop="matAutocomplete" panelWidth="300px">
              <mat-option *ngFor="let option of employeeListAbs" [value]="option.name" (click)="setmanagersId(option)">
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error class="mapError"
              *ngIf="!usersFormGroup.get('managers_id').valid && usersFormGroup.get('managers_id').touched">
              Relationship or Manager selection is required.
            </mat-error>
          </mat-form-field>
          <!-- <mat-form-field class="w-100">
                  <mat-select required placeholder='Employee Responsible or Manager' formControlName="managers_id" (onSelect)="setmanagersId($event)">
                    <mat-option *ngFor="let element of employeeListAbs" [value]="element.id">{{element.name}}</mat-option>
                  </mat-select>
                  <mat-error class="mapError" *ngIf="!usersFormGroup.get('managers_id').valid && usersFormGroup.get('managers_id').touched">
                    Relationship or Manager selection is required.
                  </mat-error>
                </mat-form-field> -->
        </div>
        <div class="col-sm-3">
          <app-dropdowns-req [required]=true [placeholder]="'Territory'" [type]="'territory_cd'"
            [selected]="usersFormGroup.value.territory_cd" [valid]="usersFormGroup.get('territory_cd').valid"
            (onSelect)="setterritoryCd($event)" (touched)="usersFormGroup.get('territory_cd').markAsTouched()">
          </app-dropdowns-req>
          <mat-error class="mapError mat-form-field"
            *ngIf="!usersFormGroup.get('territory_cd').valid && usersFormGroup.get('territory_cd').touched">
            Territory selection is required.
          </mat-error>
        </div>
        <div class="col-sm-3">
          <app-dropdowns [placeholder]="'Branch'" [type]="'branch_cd'" [selected]="usersFormGroup.value.branch_cd"
            [valid]="usersFormGroup.get('branch_cd').valid" (onSelect)="setbranchCd($event)"
            (touched)="usersFormGroup.get('branch_cd').markAsTouched()"></app-dropdowns>
          <mat-error class="mapError mat-form-field"
            *ngIf="!usersFormGroup.get('branch_cd').valid && usersFormGroup.get('branch_cd').touched">
            Branch selection is required.
          </mat-error>
        </div>
        <div class="col-sm-3">
          <mat-form-field class="w-100">
            <mat-select placeholder="Primary Work Area" formControlName="work_area_cd"
              [(ngModel)]="usersFormGroup.value.work_area_cd">
              <mat-option *ngFor="let item of work_area_cds | keyvalue" [value]="item.key">{{item.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="!usersFormGroup.get('work_area_cd').valid && usersFormGroup.get('work_area_cd').touched">
              <span *ngIf="usersFormGroup.get('work_area_cd').errors.required">Work area Required</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <mat-form-field class="w-100">
            <input type="number" min=1000000000 max=9999999999 required matInput placeholder="Mobile Number"
              formControlName="mobile_no">
            <mat-error *ngIf="!usersFormGroup.get('mobile_no').valid && usersFormGroup.get('mobile_no').touched">
              <span *ngIf="usersFormGroup.get('mobile_no').errors.required">Mobile No is required.</span>
              <span *ngIf="usersFormGroup.get('mobile_no').errors.min">Mobile No is not valid.</span>
              <span *ngIf="usersFormGroup.get('mobile_no').errors.max">Mobile No should have max 10 digits</span>
              <span *ngIf="usersFormGroup.get('mobile_no').errors.pattern">Mobile No is not correct pattern</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 pTop10 pBtm10 text-center">
          <button mat-raised-button color="primary" (click)="submitUserInformation(usersFormGroup.value)">Save</button>
          <!-- <button mat-raised-button color="primary" [disabled]="!(usersFormGroup.valid && usersFormGroup.value.termsCheck == true)" (click)="submit(usersFormGroup.value)" >Save</button>-->
        </div>
      </div>
    </form>
  </div>
</ng-template>
