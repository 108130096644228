import { Component, OnInit, Output, Input, EventEmitter, OnChanges, AfterViewInit } from '@angular/core';
import {Dropdowns} from './dropdowns'
import { Constants } from '../../core/constants';
import { DropdownsService } from '../../core/services/dropdowns.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DropdownRelations } from '../../core/dropdownRelations';
@Component({
  selector: 'app-dd-options',
  templateUrl: './ddoptions.component.html',
  styleUrls: ['./dropdowns.component.css']
})
export class DDOptopns implements OnInit, OnChanges, AfterViewInit {
  @Input() type: string;
  constructor(private dropdownsService:DropdownsService,private _formBuilder: FormBuilder) {
  }
  @Input() selected :any;
  list : Dropdowns[];
  ngOnInit() {

    this.generateListData();
  }


  generateListData(){
    this.list = new Array();


    if(Constants.DROP_DOWN_HASH && Constants.DROP_DOWN_HASH[this.type.toUpperCase()]){
      Constants.DROP_DOWN_HASH[this.type.toUpperCase()].forEach(element => {
        if (element.dd_name.toUpperCase().indexOf(this.type.toUpperCase()) === 0) {
          //if(this.checkRelation(element.dd_key)){
            this.list.push(element);
          //}
        }
      });
      //
    }
    else if(Constants.DROP_DOWN_LIST){
      Constants.DROP_DOWN_LIST.forEach(element => {
        if (element.dd_name.toUpperCase().indexOf(this.type.toUpperCase()) === 0) {
          this.list.push(element);
        }
      });
    }
      if(this.type == 'state' || this.type == 'state_cd'){
        this.list.push(new Dropdowns({'dd_key': 'Eastern Development Region', 'dd_value': 'Eastern Development Region'}));
        this.list.push(new Dropdowns({'dd_key': 'Central Development Region', 'dd_value': 'Central Development Region'}));
        this.list.push(new Dropdowns({'dd_key': 'Western Development Region', 'dd_value': 'Western Development Region'}));
        this.list.push(new Dropdowns({'dd_key': 'Mid-Western Development Region', 'dd_value': 'Mid-Western Development Region'}));
        this.list.push(new Dropdowns({'dd_key': 'Far-Western Development Region', 'dd_value': 'Far-Western Development Region'}));

      }
      if(this.type == 'truck_type_cd') {
        this.list.sort(function(a, b){
          if (a.dd_value < b.dd_value){
            return -1;
          }
          if (a.dd_value > b.dd_value) {
            return 1;
          }
          return 0;
      });

      }

    }
  ngOnChanges(){

    this.generateListData();
  }
  ngAfterViewInit() {

    this.generateListData();
  }
}
