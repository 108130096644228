import { Component, OnInit, isDevMode } from '@angular/core';
import { CommonService } from '../../shared/core/services/common.service';
import { StorageService } from '../../shared/core/services/storage.service';
import { Router } from '@angular/router';
import { NotificationsService } from '../../manage/notifications/services/notifications.service';
import { DialogService } from '../../shared/layout/dialogs/services/dialog.service';
import { Users } from '../../manage/users/users';
import io from 'socket.io-client';
import Echo from 'laravel-echo';
import { Notifications } from '../../manage/notifications/notifications';
import { CheckForUpdateService } from '../../shared/core/services/check-for-update.service';
import { LogUpdateService } from '../../shared/core/services/log-update.service';
import { PromptUpdateService } from '../../shared/core/services/prompt-update.service';
import { PushNotificationsService } from '../../shared/core/services/push-notification.service';
import { AuthenticatorService } from '../../shared/core/services/authenticator.service';
import * as appGlobals from '../../shared/core/globalfunctions';
import { CustomerBookingsService } from '../../manage/customer.bookings/services/customer.bookings.service';
declare var require: any;
// const patch = require('socketio-wildcard')(io.Manager);
// import {patch} from 'socketio-wildcard';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [NotificationsService,
    // CheckForUpdateService,
    LogUpdateService,
    // PromptUpdateService,
    PushNotificationsService,

  ]
})
export class HeaderComponent {
  notificationsList = [];
  user: Users;
  app: any;
  echo: any;
  port: any;
  badgeColor = 'primary';
  locWatchId: number;
  advanceSearchFlag:boolean = false;
  list = [];
  selected:any;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private notificationsService: NotificationsService,
    private _dialogService: DialogService,
    // private checkforUpdateService: CheckForUpdateService,
    private logUpdateService: LogUpdateService,
    // private promptUpdateService: PromptUpdateService,
    private _notificationService: PushNotificationsService,
    private authenticatorService: AuthenticatorService,
    private customerBookingsService: CustomerBookingsService
  ) {
    this._notificationService.requestPermission();
    this.list = [
      {id:1,name:'abc'},
      {id:2,name:'def'},
      {id:3,name:'ghi'}
    ]

  }
  public inputSearch: string;
  getSerachData(event) {
    if (event.keyCode == 13) {
      this.router.navigate(['customer-bookings/search-list'], { queryParams: { search: this.inputSearch }, skipLocationChange: true })
    }

  }

  ngOnInit() {

    this.pushNotify()
    this.user = StorageService.getUser();
    if (StorageService.isLogin() === false) {
   //
      // this.commonService.changeHeader(false);
      // this.router.navigate(['user/login']);
    }
    this.authenticatorService.isLoggedIn$.subscribe(value => {


    });

    this.authenticatorService.user$.subscribe(value => {

      if (value) {

      }
    });
    if (!this.user) {
      this.logout();
      return;
    }
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(position => {
    //     const lat = position.coords.latitude;
    //     const lng = position.coords.longitude;
    //     const zoom = 7;
    //   });
    // } else {
    //   alert('Geolocation is not supported by this browser.');
    // }
    // if(this.user.roles_id !== 10 && this.user.roles_id !== 3 ) {
    //
    // }
    this.getResponse();
  }
  fontSizeIncrease(){


    this.commonService.changeFont.next("increaseSize");
  }
  fontSizeDecrease(){


    this.commonService.changeFont.next("decreaseSize");
  }
  fontWeightIncrease(){


    this.commonService.changeFont.next("increaseWeight");
  }
  pushNotify() {
    let data: Array<any> = [];
    //   data.push({
    //     'title': 'Approval',
    //     'alertContent': 'This is First Alert -- By Debasis Saha'
    // });
    // data.push({
    //     'title': 'Request',
    //     'alertContent': 'This is Second Alert -- By Debasis Saha'
    // });
    // data.push({
    //     'title': 'Leave Application',
    //     'alertContent': 'This is Third Alert -- By Debasis Saha'
    // });
    // data.push({
    //     'title': 'Approval',
    //     'alertContent': 'This is Fourth Alert -- By Debasis Saha'
    // });
    // data.push({
    //     'title': 'To Do Task',
    //     'alertContent': 'This is Fifth Alert -- By Debasis Saha'
    // });

    this._notificationService.generateNotification(data);
  }
  notificationClick() {
    this.badgeColor = 'primary';
  }
  checkNotifications() {
    this.app.host =
      !this.app.host || this.app.host === null
        ? window.location.hostname
        : this.app.host;
    this.port =
      !this.app.port || this.app.port === null
        ? window.location.port
        : this.app.port;
    let host = this.app.host;
    if (this.port) {
      host = this.app.host + ':' + this.port;
      //
    }
    if (io  && this.app.echoAllowed
      && !isDevMode()
    ) {
      //
      this.echo = new Echo({
        broadcaster: 'socket.io',
        key: this.app.key,
        host: host,
        disableStats: true,
        client: io,
        namespace: 'App\\Events',
        auth: {
          headers: { Authorization: 'Bearer ' + StorageService.getToken() }
        },

        // cluster: process.env.MIX_PUSHER_APP_CLUSTER,
        // encrypted: true
      });
      if (this.echo) {
        //
        //

        //

        const socket = this.echo.connector.socket;
        const patch = require('socketio-wildcard')(io);
        patch(socket);

        socket.on('connect', this.onConnect);
        socket.on('disconnect', this.disconnect);
        socket.on('error', this.ioerror);
        socket.on('subscription_error', this.ioerror);
        socket.on('*', packet => {
          /* … */
          //
          //
          this.onWildListenEvent(packet);
        });
        if (this.user.id) {
          const userChannelName = 'App.User.' + this.user.id;
          //
          const aChannelNotification = this.echo
            .private(userChannelName)
            .notification(notification => {
              this.onNotificationEvent(notification);
            });
          this.echo.private(userChannelName)
            .listen('VehicleAssigned', (e) => {
              //
              //
              this.onNotificationEvent(e.data);
            });
          this.echo.private(userChannelName)
            .listen('TaskCreated', (e) => {
              //
              //
              this.onNotificationEvent(e.data);
            });
        }
        if (this.user && this.user!= null && this.user.id && (this.user.id === 1 || this.user.roles_id === 10)) {
          const adminChannelName = 'App.Admin';
          const aAdminChannelNotification = this.echo
            .private(adminChannelName)
            .notification(notification => {
              //
              //
              this.onAdminNotificationEvent(notification);
            });
          this.echo
            .private(adminChannelName)
            .listen('AdminBroadcast', (e) => {
              //
              //
              this.onAdminNotificationEvent(e.data);
            });
          this.echo
            .private('App.WorkArea')
            .listen('TaskCreated', (e) => {
              //
              //
              this.onAdminNotificationEvent(e.data);
            });

          /*
                notification(callback: Function): Channel {
                  return this.listen('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', callback);

            */

        } else {
          //
        }
      }
    }
  }
  notificationLength() {
    return this.notificationsList.length;
  }
  onWildListenEvent(packet: any): any {
    // throw new Error("Method not implemented.");
    // if (!this.notificationsList) {
    //   this.notificationsList = new Array();
    // }
    if (this.user.id && (this.user.id === 1)){
      //
    }

    // if (packet['data']['message'] ) {
    //   this.notificationsList.unshift(packet['data']['message'] );
    // }
  }
  ioerror(error): any {
    //
    //
  }
  disconnect(arg0: string, disconnect: any): any {

    //
    // throw new Error("Method not implemented.");
  }
  onConnect(arg0: string, onConnect: any): any {
    //
    // throw new Error("Method not implemented.");
  }
  onListenEvent(channelName: string, eventName: string, data: any): any {
    //
  }
  onNotificationEvent(data: any): any {
    //
    /*
created_at: "2019-01-14 09:47:41"
data:
message: "Activity api/customerContacts/page by user Sid Kumar"
title: "Activity api/customerContacts/page by user Sid Kumar"
__proto__: Object
deleted_at: null
id: "c170ccdb-f80e-44dc-8eeb-20c9b8387cd5"
notifiable_id: 1
notifiable_type: "App\User"
read_at: null
type: "App\Notifications\AdminNotifications"
updated_at: "2019-01-14 09:47:41"

id: "a60a9f5f-df28-4bad-880c-480530e47d9f"
message: "Activity api/customerContacts/page by user Sid Kumar"
socket: null
title: "Activity api/customerContacts/page by user Sid Kumar"
type: "App\Notifications\UserNotification"
    */
   if (this.user.id && (this.user.id === 1)){
    //
   }
  //
    let aNotification = new Notifications();
    aNotification.id = data.id;
    aNotification.notifiable_id = this.user.id;
    aNotification.notifiable_type = 'AppUser';
    aNotification.type = data.type;
    aNotification.data = data;
    if (!this.notificationsList) {
      this.notificationsList = new Array();
    }
    if (data['message']) {
      this.notificationsList.unshift(aNotification);
      this.badgeColor = 'warn';
      if(!data['alertContent']) {
        data['alertContent'] = data['message'];
      }
      if(!data['title']) {
        data['title'] = 'Admin Notification';
      }
      this._notificationService.generateNotification([data]);
      this.notificationsService.updatenotificationsList(this.notificationsList);
    }
    else {
      //
      //
    }

  }
  onAdminNotificationEvent(data: any) {
    //
    if (this.user.id && (this.user.id === 1)){
      //
     }
    let aNotification = new Notifications();
    aNotification.id = data.id;
    aNotification.notifiable_id = this.user.id;
    aNotification.notifiable_type = 'AppAdmin';
    aNotification.type = data.type;
    aNotification.data = data;
    if (!this.notificationsList) {
      this.notificationsList = new Array();
    }
    if (data['message']) {
      //
      this.notificationsList.unshift(aNotification);
      this.badgeColor = 'warn';
      if(!data['alertContent']) {
        data['alertContent'] = data['message'];
      }
      if(!data['title']) {
        data['title'] = 'Admin Notification';
      }
      this._notificationService.generateNotification([data]);
      this.notificationsService.updatenotificationsList(this.notificationsList);
    }
    else {
      //
      //
    }

  }
  getResponse() {
    return this.notificationsService.getList().subscribe(
      response => {
        //
        this.notificationsList = response.data.data;
        this.app = response.data.sapps[0];
        this.checkNotifications();
      },
      error => {
        this._dialogService.openAlert({ title: 'Error', message: appGlobals.getDisplayErrorMessage(error) });
      }
    );
  }
  logout() {
    //
    this.authenticatorService.signOut();
    this.commonService.changeHeader(false);
    this.router.navigate(['user/login']);
  }

  createBooking(){
    this.router.navigate(['/bookings/quick-order']);
  }
  get userCanCreateOrder()  {
    return StorageService.userCanCreateOrder();
  }
  getCompanyName(){
    //
    let company_name = StorageService.getItem('company_name') ;
    if(this.user && this.user != null && (!company_name || company_name == null)){
      if(this.user.work_area_cd && this.user.work_area_cd[0]){
        company_name = this.user.work_area_cd[0];
      }
    }
    return company_name;

  }

  onClickAdvanceSearch(){
    this.advanceSearchFlag = !this.advanceSearchFlag;
  }
}
