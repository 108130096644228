<div class="formWhiteBox">
    <div class="row justify-content-md-center">
        <div class="col-md-6">
			<div class="sign-logo text-center">
				<a href="#">
				  <img src="assets/img/glasswing_logo.svg">
				</a>
			</div>
			<div class="loginMainDiv">
			<h1 class="pBtm0">Reset Your Password</h1>
			<form class="example-form forg-rest-pass" [formGroup]="resetFormGroup">
				<mat-form-field class="w-100">
					<input type="text" readonly matInput placeholder="Email"  formControlName="login" >
					<mat-error *ngIf="!resetFormGroup.get('login').valid && this.resetFormGroup.get('login').touched">
						Please enter your registered email.
					</mat-error>
				</mat-form-field>
				<mat-form-field class="w-100">
					<input type="password"  matInput placeholder="Password"  formControlName="newpassword">
					<mat-error *ngIf="!resetFormGroup.get('newpassword').valid && this.resetFormGroup.get('newpassword').touched">
						Password is Not Valid
					</mat-error>
				</mat-form-field>
				<mat-form-field class="mBtm15 w-100">
					<input type="password"  matInput placeholder="Confirm Password" formControlName="confirm_password">
					<mat-error *ngIf="!resetFormGroup.get('confirm_password').valid && this.resetFormGroup.get('confirm_password').touched">
						New Password is Not Valid
					</mat-error>
				</mat-form-field>
				<div class="row mBtm10">
					<div class="col-sm-6 text-left">
					   <button mat-raised-button color="primary" (click)="submit(resetFormGroup.value)" >Reset Password</button>
					</div>
                <div class="col-sm-6 text-right">
					   <button mat-raised-button [routerLink]="['../']" >Cancel</button>
					</div>
				</div>
            </form>
        </div>
        </div>
    </div>
</div>
