<div class="ath-bth-priority-dialog">
  <h2 mat-dialog-title class="title">{{ heading }}</h2>
  <mat-dialog-content>
    <div class="sub-text">{{ subtext }}</div>
    <form *ngIf="multipleSelect">
      <mat-form-field appearance="fill" class="input-class w-100">
        <!-- <mat-label>Favorite food</mat-label> -->
        <mat-select name="option" [(ngModel)]="selectedOption">
          <mat-option [disabled]="true"> Select Option </mat-option>
          <mat-option *ngFor="let food of optionValue" [value]="food.value">
            {{ food.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="w-100 input-class"
        appearance="fill"
        *ngIf="selectedOption === 'steak-0'"
      >
        <input
          matInput
          placeholder="Remarks"
          name="remark"
          [(ngModel)]="remarks"
        />
      </mat-form-field>
    </form>
    <form *ngIf="priority">
      <mat-form-field class="w-100 input-class" appearance="fill">
        <input
          matInput
          placeholder="Remarks"
          name="remark"
          [(ngModel)]="remarks"
        />
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      class="submit-btn"
      (click)="close()"
      *ngIf="priority"
      [disabled]="remarks.length === 0"
      [ngClass]="{ 'sumbit-btn-disabled': remarks.length === 0 }"
    >
      Submit
    </button>
    <button
      mat-button
      class="submit-btn"
      (click)="close()"
      *ngIf="multipleSelect"
      [disabled]="selectedOption === undefined || remarksCondition()"
      [ngClass]="{
        'sumbit-btn-disabled':
          selectedOption === undefined || remarksCondition()
      }"
    >
      Submit
    </button>
  </mat-dialog-actions>
</div>
