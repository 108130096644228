import { SwUpdate } from '@angular/service-worker';
import { Injectable } from '@angular/core';

@Injectable()
export class PromptUpdateService {

  constructor(updates: SwUpdate) {
    if (!updates.isEnabled) {

    } else {
      updates.available.subscribe(event => {

        // if (promptUser(event)) {
           updates.activateUpdate().then(() => {

            alert('There is a new update available. Reloading the app. Please wait.');
            document.location.reload();
            });
        // }
      });

    }
  }
}
