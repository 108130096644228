import { NgModule } from '@angular/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
// import { MatSelectFilterModule } from 'mat-select-filter';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { MaterialTimePickerModule } from '@candidosales/material-time-picker';
import {MatTooltipModule} from '@angular/material/tooltip';
import { GoogleMapsModule } from '@angular/google-maps';
@NgModule({
  imports: [
    LoadingBarHttpClientModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    // MatSelectFilterModule,
    MatSidenavModule,
    MatChipsModule,
    MatListModule,
    MatTooltipModule,
    // MaterialTimePickerModule,
    GoogleMapsModule,
  ],
  declarations: [

   ],
  exports: [
    LoadingBarHttpClientModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    // MatSelectFilterModule,
    MatSidenavModule,
    MatChipsModule,
    MatListModule,
    MatTooltipModule,
    // MaterialTimePickerModule,
    GoogleMapsModule,
  ]
})
export class SharedMinmatModule { }
