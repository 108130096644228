import { SwUpdate } from '@angular/service-worker';
import { Injectable } from '@angular/core';

@Injectable()
export class LogUpdateService {

  constructor(updates: SwUpdate) {
    if (!updates.isEnabled) {

    } else {
      updates.available.subscribe(event => {


      });
      updates.activated.subscribe(event => {


      });

    }
  }
}
