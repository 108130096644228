import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatErrorComponent } from './mat-error.component';
import { MatFormFieldModule } from '@angular/material/form-field';
@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
  ],
  declarations: [
    MatErrorComponent,
  ],
  exports: [
    MatErrorComponent,
  ]
})
export class MatErrorModule {
}
