import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpService } from '../../../shared/core/services/http-service.service'
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '../../../shared/core/model/response';
@Injectable({
  providedIn:'root'
})
export class CustomerBookingsService {
  constructor(private serviceHttp:HttpService,private http:HttpClient) { }
  getList(pageNo = 1
    ,bookings_customers_id?: any
    ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/page', 'POST', 0, {page: pageNo
        ,customers_id: bookings_customers_id
      });
  }
  search(str:string  ,bookings_customers_id?: any ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/search', 'POST', 0, {search:str, customers_id: bookings_customers_id
    });
  }


  getBookingsById(id): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('bookings/'+ id, 'GET');
  }
  addBookings(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('bookings', 'POST', 0, data);
  }
  getLocationCode(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/locationcode', 'POST', 0, data, 0, 1);
  }
  addquote(data):Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/addquote', 'POST', 0, data);
  }
  getFreight(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('freights/search', 'POST', 0, data);
  }
  cancelBooking(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/updateStatus/'+data.id, 'PUT', 0, data);
  }
  updateStatus(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/updateStatus/'+data.id, 'PUT', 0, data);
  }
  updateBookings(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/'+data.id, 'PUT', 0, data);
  }
  searchBookingList(str:string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('bookings/search', 'POST', 0, {search:str});
  }

  getListBySpotBooking(pageNo = 1
    ,booking_type_cd="SPOT",bookings_customers_id?: any
    ): Observable<ResponseModel> {

    return this.serviceHttp.getResponse('customer/bookings', 'POST', 0, {page: pageNo
        ,booking_type_cd:booking_type_cd,customers_id: bookings_customers_id
      });
  }
   getListByBookingStatus(pageNo = 1
    ,booking_status_cd="All",screen="Active",bookings_customers_id?: any
    ): Observable<ResponseModel> {

    return this.serviceHttp.getResponse('customer/bookings', 'POST', 0, {page: pageNo,booking_status_cd: booking_status_cd
        ,screen: screen,customers_id: bookings_customers_id
      });
  }

  getCompltedListByBookingStatus(pageNo = 1
    ,booking_status_cd="All",screen="Completed",bookings_customers_id?: any
    ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customer/bookings', 'POST', 0, {page: pageNo,booking_status_cd: booking_status_cd
        ,screen: screen,customers_id: bookings_customers_id
      });
  }

  getUnprocessedListByBookingStatus(pageNo = 1
    ,booking_status_cd="All",screen="Unfulfilled",bookings_customers_id?: any
    ): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('customer/bookings', 'POST', 0, {page: pageNo,booking_status_cd: booking_status_cd
        ,screen: screen,customers_id: bookings_customers_id
      });
  }

  public searchList = new BehaviorSubject<any>(null);

  getSeacrhData(str:string,pageNo= 1 ): Observable<any> {

    return this.serviceHttp.getResponse('bookings/search', 'POST', 0, {search:str,page: pageNo

    })

  }

  getrouteData(start:string,end:string): Observable<any> {

    return this.http.get<any>('https://www.mapmyindia.com/call_route/route.php?start='+start+'&end='+end);

  }

  gettripsData(tripid:any ): Observable<any> {

    return this.serviceHttp.getResponse('trips', 'POST', 0, {id:tripid

    })

  }
  getTripsLocations(tripid:any):Observable<any> {

    return this.serviceHttp.getResponse('drivers/getTripsLocations', 'POST', 0, {trips_id:tripid
    })

  }


  //https://api.glasswing.in/api/drivers/getTripsLocations







}
