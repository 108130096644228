import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../shared/core/services/http-service.service'

import { ResponseModel } from '../../../shared/core/model/response';
@Injectable()
export class UsersService {
    constructor(private serviceHttp:HttpService) { }
    getList(aData): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('users/page', 'POST', 0, aData);
    }
    getBidPartnerUsersList(pageNo:number, searchTerm?): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('users/bidPartnerUsersList?page='+pageNo+'&search=' +searchTerm , 'GET');
    }
    getAllUserList(): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('users/all/', 'GET');
    }
    getUsersById(id): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('users/'+ id, 'GET');
    }
    addUsers(data): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('users', 'POST', 0, data, 1);
    }
    updateUsers(data): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('users/'+data.id, 'PUT', 0, data, 1);
    }
    userSignUp(data): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('register', 'POST', 0, data);
    }
    getRoleList(): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('roles', 'GET');
    }
    login(data): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('login', 'POST', 0, data);
    }
    searchUsers(str:string): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('users/search', 'POST', 0, {search:str});
    }
    searchUsersMultiple(aData: any): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('users/search', 'POST', 0, aData);
  }
    forgotpassword(data): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('forgotpassword', 'POST', 0, data);
    }
    validateMobile(data:any):Observable<ResponseModel> {
        return this.serviceHttp.getResponse('users/verifymobileoremail', 'POST', 0, {id:data.value.users_id,mobile_no:data.value.user.mobile_no});
    }
    validateEmail(data:any):Observable<ResponseModel> {
        return this.serviceHttp.getResponse('users/verifymobileoremail', 'POST', 0, {id:data.value.users_id,email:data.value.user.email});
    }
    decryptUsername(data): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('decryptemail/'+ data, 'GET', 0, data);
    }
    resetPassowrd(data): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('resetpasscode', 'POST', 0, data);
    }
    blockUser(id): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('users/block/'+id, 'GET');
    }
    verifyUser(id): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('users/verify/'+id, 'GET');
    }
    sendEmailPassword(email): Observable<ResponseModel> {
        return this.serviceHttp.getResponse('users/email_password/'+email, 'GET');
    }
    pushNotify(data): Observable<ResponseModel> {
      return this.serviceHttp.getResponse('users/pushNotify', 'POST', 0, data);
  }
  getEmployees(str: string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('users/employees', 'POST', 0, {search:str}, 0, 1);
    // TODO: Get all employees for now may be cache by group and region locally like dropdown
    // return this.serviceHttp.getResponse('users/employees', 'POST', 0, {}, 0, 0);
  }
  getEmployeesWithLoading(str: string): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('users/employees', 'POST', 0, {search:str}, 0, 0);
    // TODO: Get all employees for now may be cache by group and region locally like dropdown
    // return this.serviceHttp.getResponse('users/employees', 'POST', 0, {}, 0, 0);
  }
  getLocationHistory(id): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('drivers/getLocationsForUser/'+id, 'GET');
  }
  getUsersLocations(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('drivers/getUsersLocations', 'POST', 0, data);
  }
  getTripsLocations(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('drivers/getTripsLocations', 'POST', 0, data);
 }
 getTelenityLocation(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('users/getTelenityLocation', 'POST', 0, data);
 }
 getIntugineGetConsent(data): Observable<ResponseModel> {
    return this.serviceHttp.getResponse('getIntugineGetConsent', 'POST', 0, data);
 }
 startTripIntugine(data): Observable<ResponseModel> {
  return this.serviceHttp.getResponse('startTripIntugine', 'POST', 0, data);
}
intugineEndTrip(data): Observable<ResponseModel> {
  return this.serviceHttp.getResponse('intugineEndTrip', 'POST', 0, data);
}
intugineFetchLocation(data): Observable<ResponseModel> {
  return this.serviceHttp.getResponse('intugineFetchLocation', 'POST', 0, data);
}


}
