export class Roles {
    id : number;
    name : string;
    guard_name : string;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : Roles[] {
        let roles : Roles[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                roles.push(new Roles(json));
            }
        }
        return roles;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.name = json.name;
            this.guard_name = json.guard_name;
        }
    }
}
