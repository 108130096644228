<div class="wrapper" [ngStyle]="variableStyle">
  <button *ngIf="isShow" class="leftToggleMenu" type="button" >
    <span class="ti-menu" (click)="toggleDrawer()"></span>
  </button>
  <app-header *ngIf="isShow"></app-header>
  <!-- <ngx-loading-bar></ngx-loading-bar> -->
  <!-- <mat-progress-bar mode="determinate" [value]="loader.progress$|async"></mat-progress-bar> -->
  <mat-drawer-container class="example-container" autosize >
  <!-- (click)="disableSideMenu()"> -->
    <mat-drawer #drawer class="example-sidenav left-sidenav" mode="side" hasBackdrop="false">
      <div class="leftMenu" *ngIf="isShow">
        <app-left-menu></app-left-menu>
      </div>
    </mat-drawer>
    <div class="mainContainer">
      <ngx-loading-bar [fixed]="false" #loadingBarMain></ngx-loading-bar>
      <!-- <div class="ngx-spinner center-screen">
        <div class="ngx-spinner-icon" style="width: 14px;
      height: 14px;
      border: 2px solid transparent;
      border-top-color: inherit;
      border-left-color: inherit;
      border-radius: 50%;
      -webkit-animation: .4s linear infinite loading-bar-spinner;
      animation: .4s linear infinite loading-bar-spinner;
      color: rgb(34, 153, 221)">
        </div>
      </div> -->
      <div class="block-screen" *ngIf="loaderValue">Loading.. please wait</div>
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
  <app-footer *ngIf="isShow"></app-footer>
</div>
