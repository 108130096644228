import { switchMap } from 'rxjs/operators';

import { debounceTime } from 'rxjs/operators';
import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { CommonService } from '../../shared/core/services/common.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Users, usersFormControls } from '../../manage/users/users';
import { UsersService } from '../../manage/users/services/users.service';
import { StorageService } from '../../shared/core/services/storage.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthenticatorService } from '../../shared/core/services/authenticator.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DialogService } from '../../shared/layout/dialogs/services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as appGlobals from '../../shared/core/globalfunctions';
import { DropdownsService } from '../../shared/core/services/dropdowns.service';
import { work_areas } from '../../shared/core/constants';
// import { RollbarService } from '../../core/services/rollbar.service';
// import * as Rollbar from 'rollbar';
import { AppSettings } from '../../shared/core/config';
import { Observable, Observer } from 'rxjs';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [
    UsersService,
    BsModalService,
    DialogService,
    DropdownsService
  ]
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginFormGroup: FormGroup;
  errorMessage: string = null;
  hidePassword = true;
  authIsLoaded = false;
  isLoggedIn = false;
  isError = null;
  user: Users;
  modalRef: BsModalRef;
  errorMessages: any;
  employeeListAbs: Users[];
  locWatchId: any;
  work_area_cds: {};
  usersFormGroup: FormGroup;
  @ViewChild('territoryInfo', { static: true }) public territoryInfo: TemplateRef<any>;
  constructor(
    private _titleService: Title,
    private commonService: CommonService,
    private modalService: BsModalService,
    private _dialogService: DialogService,
    private _snackBarService: MatSnackBar,
    private _formBuilder: FormBuilder,
    private usersService: UsersService,
    private router: Router,
    private authenticatorService: AuthenticatorService,
    // @Inject(RollbarService) private rollbar: Rollbar
  ) {

  }

  ngOnInit() {
 //  ('login ngoninit');
    this.isLoggedIn = false;
    this.authIsLoaded = false;
    this.work_area_cds = work_areas;
 //  (StorageService.hasLoc());
    // this.getLocation();
    this.loginFormGroup = this._formBuilder.group({
      login: ['', [Validators.required, Validators.minLength(6)]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    this.usersFormGroup = this._formBuilder.group(usersFormControls);
    if (StorageService.isLogin()) {
      this.checkInfoAndTakeToLandingPage();
    }
    this.authenticatorService.isLoaded$.subscribe(value => {
      this.authIsLoaded = value;
    });

    this.authenticatorService.isLoggedIn$.subscribe(value => {
      this.isLoggedIn = value;
      if (value === true) {
        this.checkInfoAndTakeToLandingPage();
      }
      else if(value === false) {
        this.authenticatorService.disableRollbar();
        this.commonService.changeHeader(false);
        this.router.navigate(['user/login']);
      }
    });
    this.authenticatorService.isError$.subscribe(response => {
      if (response != null) {
        this._dialogService.openAlert({
          title: 'Error',
          message: appGlobals.getDisplayErrorMessage(response)
        });
      }
    });
    this.authenticatorService.user$.subscribe(value => {
      this.user = value;
    });
  }
  ngAfterViewInit() {
 //  'after view init'
    if(!this.authIsLoaded) {

    }
  }
  checkInfoAndTakeToLandingPage() {
    // check if its employee and if region, manager, branch are asssigned
 //  (StorageService.isLogin());
    let goToLanding = true;
    if (StorageService.isLogin()) {
      let user = StorageService.getUser();
      if (user != null && user.roles_id === 1) {
        if (!user.territory_cd) {
          this.setEmployeeLookup();
          this.usersService.getEmployees('').subscribe(response => {
            if (response.data.data) {
              this.employeeListAbs = response.data.data;
              this.usersFormGroup.patchValue(user);
              this.modalRef = this.modalService.show(this.territoryInfo);
              goToLanding = true;
            }
          });
        }

      }
      if (user && user != null) {
        this.authenticatorService.enableRollbar();
      }
   //  (goToLanding);
      if(goToLanding){
        if(user.roles_id === 3) {
          this.router.navigate(['dashboard']);
        }
        else if(StorageService.isInternalErp$.value){
          (user);
          const userWorkAreaCd = user.work_area_cd[0];
          (userWorkAreaCd);
          if(StorageService.checkWA('MANAGEMENT')) {
            this.router.navigate(['/1/triplite/tasks']);
          }
          else {
            this.router.navigate(['/1/triplite/internal/workarea/', userWorkAreaCd, user.id]);
          }
          // if(userWorkAreaCd == 'TRAFFIC EXECUTIVE') {

          // }
          // else {
          //   this.authenticatorService.signOut();
          //   this.commonService.changeHeader(false);
          //   this.router.navigate(['user/login']);

          // }
        }
        else {
          this.router.navigate(['/1/triplite/']);
        }
        //
      }
    }
  }

  setEmployeeLookup() {
    this.usersFormGroup.controls.managers_name.valueChanges
      .pipe(
        debounceTime(400),
        switchMap(term => {
          return this.usersService.getEmployees(term);
        })
      )
      .subscribe(
        response => {
          this.employeeListAbs = (<any>response).data.data;
        },
        error => {
          alert(error);
        }
      );
  }
  setmanagersId(option) {
    this.usersFormGroup.patchValue({
      managers_id: option.id
    });
 //  (option);
    this.usersFormGroup.patchValue({
      managers_name: option.name
    });
  }
  setworkareaCd(event) {
    this.usersFormGroup.patchValue({
      work_area_cd: event
    });
  }
  setbranchCd(event) {
    this.usersFormGroup.patchValue({
      branch_cd: event
    });
  }
  setterritoryCd(event) {
    this.usersFormGroup.patchValue({
      territory_cd: event
    });
  }
  signInByGoogle(): void {
    this.authenticatorService.signInByGoogle();
  }
  signInByOffice(): void{
 //  ('signing by office');
    this.authenticatorService.signInByMicrosoft();
  }
  signOut(): void {
    this.isLoggedIn = false;
    this.authenticatorService.signOut();
  }
  submit(data) {
    this.authenticatorService.signInByWeb(data);
  }
  onSignIn(googleUser) {
    var profile = googleUser.getBasicProfile();
 }
  submitUserInformation(data) {
    if (!this.usersFormGroup.valid) {
      this.errorMessages = appGlobals.getFormValidationErrors(
        this.usersFormGroup
      );
      appGlobals.markFormGroupTouched(this.usersFormGroup);
    } else {
   //  (data);
      delete data['password'];
      this.usersService.updateUsers(data).subscribe(
        response => {
          if (response.status === 200) {
            StorageService.setUser(new Users(response.data));
            this._snackBarService.open(response.message, 'X', {
              duration: 2000
            });
            this.modalRef.hide();
            this.checkInfoAndTakeToLandingPage();
          } else {
            this._dialogService.openAlert({
              title: 'Error',
              message: appGlobals.getDisplayErrorMessage(response)
            });
          }
        },
        error => {
          this._dialogService.openAlert({
            title: 'Error',
            message: appGlobals.getDisplayErrorMessage(error)
          });
        }
      );
    }
  }
  clearWatchLocation() {
    if (this.locWatchId) {
      navigator.geolocation.clearWatch(this.locWatchId);
    }
  }
  getLocationObserver(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
        if(window.navigator && window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                (position) => {
                    observer.next(position);
                    observer.complete();
                },
                (error) => observer.error(error)
            );
        } else {
            observer.error('Unsupported Browser');
        }
    });
  }
  getLocation() {
    this.getLocationObserver().subscribe(
      position => {
     //  ('location changed');
     //  (position);
     //  (position.coords);
        StorageService.saveLocation(position.coords);
      },
      error => {
        let result = true;
     //  (error);
        switch (error.code) {
          case error.PERMISSION_DENIED:
            result = false;
            this._dialogService.openAlert({
              title: 'Error',
              message:
                'Location services are required for ' +
                'this system to work. Please contact system administrator.'
            });
            this.authenticatorService.signOut();
            break;
          case error.POSITION_UNAVAILABLE:
          this._dialogService.openAlert({
            title: 'Error',
            message:
              'Location information is unavailable. Please switch on location in settings.'
          });
         //  ('Location information is unavailable in login. ');
            break;
          case error.TIMEOUT:
         //  ('The request to get user location timed out.');
            break;
        }
        return result;
      }
    );
  }
}
