export class Dropdowns {
  id : number;
  dd_label : string;
  dd_name : string;
  dd_key : string;
  dd_value : string;
  dd_lang : string;
  dd_value_short: string;
  constructor(json? : any) {
      this.fill(json);
  }
  static toArray(jsons : any[]) : Dropdowns[] {
      let dropdowns : Dropdowns[] = [];
      if (jsons != null) {
          for (let json of jsons) {
              dropdowns.push(new Dropdowns(json));
          }
      }
      return dropdowns;
  }
  fill(json? : any){
      if (json != null) {
          this.id = json.id;
          this.dd_label = json.dd_label;
          this.dd_name = json.dd_name;
          this.dd_key = json.dd_key;
          this.dd_value = json.dd_value;
          this.dd_value_short = json.dd_value_short;
          this.dd_lang = json.dd_lang;
      }
  }
}
