import { Component, ElementRef, ViewChild, Inject, NgZone } from '@angular/core';
import { CommonService } from './shared/core/services/common.service';
import { DropdownsService } from './shared/core/services/dropdowns.service';
import { Constants } from './shared/core/constants';
import { HttpService } from './shared/core/services/http-service.service';
import { AppSettings } from './shared/core/config';
import { StorageService } from './shared/core/services/storage.service';
import { PromptUpdateService } from './shared/core/services/prompt-update.service';
import { CheckForUpdateService } from './shared/core/services/check-for-update.service';
import { MatDrawer } from '@angular/material/sidenav';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { LOCALE_ID } from '@angular/core';
import { LoadingBarState } from '@ngx-loading-bar/core/loading-bar.state';
import { NavigationError, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    PromptUpdateService,
    CommonService,
    DropdownsService,
    HttpService,
    CheckForUpdateService,
  ]
})
export class AppComponent {
  showFiller = false;
  isShow: boolean;

  @ViewChild('drawer', { static: true }) public drawer: MatDrawer;
  loader: LoadingBarState;
  loaderValue: number;
  variableStyle = {};
  fontSize = 0.6875;
  fontWeight = 400;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private dropdownsService: DropdownsService,
    private serviceHttp: HttpService,
    private promptUpdateService: PromptUpdateService,
    private checkforUpdateService: CheckForUpdateService,
    protected loadingBar: LoadingBarService,
    private ngZone: NgZone,
    @Inject(LOCALE_ID) locale: string
  ) {
    // this.commonService.setDrawer(this.drawer);

    this.router.events.pipe
      (filter((e): e is NavigationError => e instanceof NavigationError))
      .subscribe((evt) => {
        if (evt.error.message.startsWith('Loading chunk')) {
          alert('There is a new update available. Reloading the app. Please wait.');
          // document.location.reload();
          window.location.reload();
        }
        else{

        }
      });

  }

  ngOnInit() {
    this.commonService.castLogin$.subscribe(isShow => {
      setTimeout(() => {
        this.isShow = isShow;
      });
    });
    this.variableStyle = {'font-size': this.fontSize + 'rem'};
    this.commonService.fontChanger$.subscribe(
      fontValue => {


        if(fontValue == 'increaseSize') {
          this.fontSize = this.fontSize + 0.1 * this.fontSize;
          this.variableStyle = {'font-size': this.fontSize + 'rem', 'font-weight': this.fontWeight};
        }
        else if(fontValue == 'decreaseSize') {
          this.fontSize = this.fontSize - 0.1 * this.fontSize;
          this.variableStyle = {'font-size': this.fontSize + 'rem', 'font-weight': this.fontWeight};
        }
        else if(fontValue == 'increaseWeight') {
          this.fontWeight = this.fontWeight  + 100;
          this.variableStyle = {'font-size': this.fontSize + 'rem', 'font-weight': this.fontWeight};
        }
      }
    );
    this.commonService.setDrawer(this.drawer);
    this.loader = this.loadingBar.useRef('http');
    // this.loader.start(20);

    this.loader.value$.subscribe(next => {
      this.loaderValue = next;
    });

  }
  toggleDrawer() {

    this.commonService.toggle();
  }

  disableSideMenu(){
    this.commonService.close();
  }

}
