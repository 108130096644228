import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-sub-menus',
  templateUrl: './sub-menus.component.html',
  styleUrls: ['./sub-menus.component.css']
})
export class SubMenusComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}
