import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';
import * as Rollbar from 'rollbar';

const rollbarConfig = {
  accessToken: '46884a775d024c69af5f02738cb117d8',
  captureUncaught: false,
  captureUnhandledRejections: false,
  enabled: false,
  autoInstrument: {
    network: false,
    log: false,
    dom: false,
    navigation: false,
    connectivity: false,
    enabled: false,
  }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err:any) : void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
