<!-- <mat-form-field class="w-100 mat-form-field {{_invalidClass}}">
    <mat-select multiple={{multiple}} placeholder={{placeholder}} [(value)]="selected"
     (selectionChange)="selectId()" (click)="setTouchedSelf()" (keyup)="setTouchedSelf()">
      <mat-option *ngFor="let element of list"  [value]="element.dd_key">{{element.dd_value_short || element.dd_value}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-error
  *ngIf="!consignmentNotesFormGroup.get('insurance_poilicy_no').valid && consignmentNotesFormGroup.get('insurance_poilicy_no').touched">
  <span *ngIf="consignmentNotesFormGroup.get('insurance_poilicy_no').errors.required">Insurance
    policy
    number is required</span>
</mat-error> -->
<mat-error *ngIf="shouldDisplayError()">
  <span *ngIf="hasRequiredError()">{{displayName}} is required</span>
</mat-error>
