import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/core/services/common.service';
import { Router } from '@angular/router';
import { NotificationsService } from '../../manage/notifications/services/notifications.service';
import { DialogService } from '../../shared/layout/dialogs/services/dialog.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers: [NotificationsService]
})
export class FooterComponent {
  constructor(
    private router: Router,
    private commonService: CommonService,
    private notificationsService: NotificationsService,
    private _dialogService: DialogService
  ) {}
  ngOnInit() {

  }
}
