import { safeDate } from '../../shared/core/globalfunctions';

export class Notifications {
    id : string;
    type : string;
    notifiable_id : number;
    notifiable_type : string;
    data : string;
    read_at : Date;
    constructor(json? : any) {
        this.fill(json);
    }
    static toArray(jsons : any[]) : Notifications[] {
        let notifications : Notifications[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                notifications.push(new Notifications(json));
            }
        }
        return notifications;
    }
    fill(json? : any){
        if (json != null) {
            this.id = json.id;
            this.type = json.type;
            this.notifiable_id = json.notifiable_id;
            this.notifiable_type = json.notifiable_type;
            this.data = json.data;
            safeDate(this, json,  'read_at');

        }
    }
}
