import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownsComponent } from './dropdowns.component';
// import { SharedModule } from '../../shared/shared.module';
import { DropdownsReqComponent } from './dropdowns.req.component';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { DDOptopns } from './ddoptions.component';
// import { DropdownsListComponent } from './list/dropdowns.list.component';
// import { EditComponent } from './edit/edit.component';
// import { Routes, RouterModule } from '@angular/router';
// export const dropdownsRoutes: Routes = [
// 	{ path: 'dropdowns',
// 		children: [
// 			{ path: '', redirectTo: 'list', pathMatch: 'full' },
// 			{ path: 'list', component: DropdownsListComponent },
// 			{ path: 'edit/:id', component: EditComponent },
// 			{ path: 'add', component: EditComponent },
// 		]
//   },

// ];
@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    // FormsModule,
    ReactiveFormsModule,
    // SharedModule
  ],
  declarations: [
    DropdownsComponent,
    DropdownsReqComponent,
    DDOptopns,
  ],
  exports: [
    DropdownsComponent,
    DropdownsReqComponent,
    DDOptopns,
  ]
})
export class DropdownsModule {
}
